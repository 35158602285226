import { gql } from "@apollo/client"
import { Dialog as MuiDialog, DialogActions, DialogContent } from "@material-ui/core"

import { Box, Button, DatePicker, Field, Grid, Select, Typography } from "components"

import { ReportType } from "constants/objects/report-type"
import { compose, withFormik, withHooks, withStores } from "enhancers"
import styled from "styled-components"

const Dialog = styled(MuiDialog)`
  .MuiDialog-paperScrollPaper {
    padding: 0;
    margin: 1vw;
    min-width: 343px;
    overflow: auto;
  }
`
const ModalContainer = styled(DialogContent)`
  min-width: 560px;
  height: 100%;
  padding: 16px;
`
const RowContainer = styled("div")`
  display: flex;
  flex-direction: row;
`
const FieldContainer = styled(Box)`
  margin: 16px 0px;
`
const ActionContainer = styled(Box)`
  display: flex;
  width: 100%;
  padding: 0px 16px 16px 16px;
`
const ActionButton = styled(Button)`
  width: 100%;
`

const ReportFilterModalComponent = (props: any) => (
  <Dialog open={props.isOpen}>
    <ModalContainer>
      <Typography variant="h3">ออกรายงาน</Typography>

      <FieldContainer>
        <Typography variant="body1">ประเภทรายงาน</Typography>
        <RowContainer>
          <Field
            style={{ width: "100%" }}
            component={Select}
            label=""
            name="format"
            options={props.reportFormatOptions}
          />
        </RowContainer>
      </FieldContainer>

      <FieldContainer>
        <Typography variant="body1">ช่วงวันที่ของข้อมูล</Typography>
        <Grid style={{ position: "relative" }} container spacing={8}>
          <Grid item style={{ marginTop: "1px" }} xs={6}>
            <Field component={DatePicker} label="" name="startDate" format="dd/MM/yyyy" />
          </Grid>
          <div style={{ position: "absolute", top: "35%", right: "294px" }}>-</div>
          <Grid item xs={6}>
            <Field component={DatePicker} label="" name="endDate" format="dd/MM/yyyy" />
          </Grid>
        </Grid>
      </FieldContainer>
    </ModalContainer>
    <DialogActions>
      <ActionContainer>
        <ActionButton variant="outlined" onClick={props.handleClose} mr="16px">
          ปิดหน้าต่างนี้
        </ActionButton>
        <ActionButton disabled={props.isActionButtonDisabled} variant="contained" onClick={props.handleConfirm}>
          ยืนยัน
        </ActionButton>
      </ActionContainer>
    </DialogActions>
  </Dialog>
)

const enhancer = compose(
  withFormik({
    mapPropsToValues: (props: any) => {
      const { defaultStartDate, defaultEndDate } = props
      return {
        startDate: defaultStartDate,
        endDate: defaultEndDate,
      }
    },
  }),
  withStores((stores: any) => ({
    currentUserInfo: stores.userStore.currentUser,
  })),
  withHooks((props: any, hooks: any) => {
    const { isOpen = false, onCancel, onConfirm, defaultStartDate, defaultEndDate } = props
    const { values, setFieldValue } = props

    const { useMemo, useCallback, useEffect } = hooks

    const isActionButtonDisabled = useMemo(() => !values.format, [values])

    const reportFormatOptions = useMemo(() => {
      const options = Object.keys(ReportType).map((key) => {
        const reportType = ReportType[key as keyof typeof ReportType]
        const label = reportType === null ? key : `${reportType}-${key}`
        const value = key
        return { label, value }
      })
      return options
    }, [])

    const handleClose = useCallback(() => {
      onCancel()
    }, [onCancel])

    const handleConfirm = useCallback(async () => {
      const startDate = new Date(values.startDate)
      const endDate = new Date(values.endDate)
      if (startDate > endDate) alert("วันที่เริ่มต้นต้องน้อยกว่าวันที่สิ้นสุด")
      else {
        await onConfirm(values)
        handleClose()
      }
    }, [onConfirm, handleClose, values])

    useEffect(() => {
      if (defaultStartDate) {
        setFieldValue("startDate", defaultStartDate)
      }
    }, [setFieldValue, defaultStartDate])

    useEffect(() => {
      if (defaultEndDate) {
        setFieldValue("endDate", defaultEndDate)
      }
    }, [setFieldValue, defaultEndDate])

    return {
      isOpen,
      reportFormatOptions,
      isActionButtonDisabled,
      handleClose,
      handleConfirm,
    }
  }),
)

export const ReportFilterModal = enhancer(ReportFilterModalComponent)
