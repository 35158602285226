import { Box } from "components"
import { PropsWithChildren, useMemo } from "react"
import styled from "styled-components"
import { AppColor, backGroundColorGradient } from "theme/app-color"
import { theme } from "../constants"
import { Title } from "./PageContentTitle"
import { linearGradient } from "polished"
import { ReactComponent as Report } from "assets/icon/report.svg"
import Theme from "theme/custom"

const TitleBox = styled(Box)<{ bgcolor?: string; ["blue-background-height"]?: string }>`
  position: relative;
  background-color: ${(props) => (props.bgcolor ? props.bgcolor : theme.color.white)};
  padding-bottom: ${(props) => props["blue-background-height"]};
  display: flex;
  justify-content: center;
  align-items: center;
`

const LayoutBox = styled(Box)<{ ["overlap-height"]?: string }>`
  position: relative;
  margin-top: -${(props) => (props["overlap-height"] ? props["overlap-height"] : "0px")};
`

const BlueContainer = styled(Box)<{ ["blue-container-height"]?: string }>`
  position: relative;
  background: ${AppColor["Dark Blue/Primary Text"]};
  height: ${(props) => (props["blue-container-height"] ? props["blue-container-height"] : "0px")};
`
const BgBlueContainer = styled(Box)<{ ["blue-container-height"]?: string }>`
  position: relative;
  background: ${AppColor["Dark Blue/Primary Text"]};
  height: ${(props) => (props["blue-container-height"] ? props["blue-container-height"] : "0px")};
`

const ChildrenContainer = styled.div`
  position: relative;
  height: 100%;
`

const PageContentConfig = {
  primary: {
    titleColor: AppColor["White / White"],
    titleBackgroundColor: Theme.backgroundGradient,
  },
  secondary: {
    titleColor: AppColor["Gray/Primary Text"],
    titleBackgroundColor: AppColor["White / White"],
  },
  linear: {
    titleColor: AppColor["White / White"],
    titleBackgroundColor: AppColor["Primary/Line"],
  },
  gray: {
    titleColor: AppColor["Text/Primary Text"],
    titleBackgroundColor: AppColor["Text/Background"],
  },
}
interface PageContentProps extends PropsWithChildren<unknown> {
  title: string
  type?: keyof typeof PageContentConfig
  titleCentered?: boolean
  onListClickTo?: string
  onBack?: () => void
  pageBackgroundColor?: string
  showHomeIcon?: boolean
  extendedTitleHeight?: string
  overlapHeight?: string

  showFilter?: boolean
  openFilterModal?: void
  hasFilter?: boolean

  showLogout?: boolean
  showDashBoardIcon?: boolean
  filterCount?: number
  noScroll?: boolean
}

export const PageContent = (props: PageContentProps) => {
  const {
    title,
    showDashBoardIcon,
    type = "primary",
    children,
    titleCentered = true,
    showHomeIcon,
    onListClickTo,
    onBack,
    pageBackgroundColor = AppColor["White / White"],

    extendedTitleHeight,
    overlapHeight,
    showFilter,
    openFilterModal,
    hasFilter,
    showLogout,
    filterCount = 0,

    noScroll = false,
  } = props

  const { titleBackgroundColor, titleColor } = useMemo(() => PageContentConfig[type], [type])
  const divId = useMemo(() => {
    return noScroll ? "no-scroll" : "scroll"
  }, [noScroll])

  return (
    <div
      className={divId}
      style={{
        backgroundColor: pageBackgroundColor,
        position: "relative",
        minWidth: "357px",
        height: "100%",
        minHeight: "100vh",
      }}
    >
      <TitleBox
        minWidth={357}
        overflow="hidden"
        bgcolor={titleBackgroundColor}
        blue-background-height={extendedTitleHeight}
      >
        <Title
          onBack={onBack}
          onListClickTo={onListClickTo}
          title={title}
          titleColor={titleColor}
          centered={titleCentered}
          showFilter={showFilter}
          openFilterModal={openFilterModal}
          hasFilter={hasFilter}
          showLogout={showLogout}
          showDashBoardIcon={showDashBoardIcon}
          showHomeIcon={showHomeIcon}
          filterCount={filterCount}
        />
      </TitleBox>
      <LayoutBox minWidth={357} maxWidth={784} mx="auto" overflow="hidden" overlap-height={overlapHeight}>
        <ChildrenContainer>{children}</ChildrenContainer>
      </LayoutBox>
    </div>
  )
}
