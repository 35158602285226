import { Typography } from "components"
import styled from "styled-components"
import { AppColor } from "theme/app-color"

interface Remark {
  description: string
}

interface ClaimRemarkProps {
  remarks: Remark[]
  step: number
}

const Container = styled.div<{ step?: number }>`
  margin-bottom: ${(props) => (props.step === 1 ? "40px" : "24px")};
`

const List = styled.ul`
  margin-top: 4px;
  margin-left: -16px;
`

const ClaimRemarks = (props: ClaimRemarkProps) => {
  const { remarks, step } = props
  return (
    <>
      <Container step={step}>
        {remarks.length > 0 && (
          <Typography variant="h5" color={AppColor["Text/Primary Text"]}>
            หมายเหตุ :
          </Typography>
        )}
        <List>
          {remarks.map((remark) => (
            <li key={`${remark.description}-0`}>
              <Typography variant="h5" color={AppColor["Text/Secondary Text"]}>
                {remark.description}
              </Typography>
            </li>
          ))}
        </List>
      </Container>
    </>
  )
}

export default ClaimRemarks
