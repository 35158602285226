import userStore from "stores/userStore"
import DefaultColors from "./default-colors"
import SCGCColors from "./scgc-colors"

const appColorConfigs = {
  default: DefaultColors,
  "บริษัทเอสซีจี เคมิคอลส์ จำกัด (มหาชน)": SCGCColors,
  SCGC: SCGCColors,
  "SCGC-Family": SCGCColors,
}

class CustomTheme {
  get colors() {
    const themeName = (): keyof typeof appColorConfigs => {
      const themeName = localStorage.getItem("themeName")
      const userDepartment = JSON.parse(themeName as string)
      const colorConfigsName = userDepartment in appColorConfigs ? userDepartment : "default"
      return colorConfigsName
    }
    return appColorConfigs[themeName()]?.colors || appColorConfigs.default.colors
  }

  get backgroundGradient() {
    const themeName = (): keyof typeof appColorConfigs => {
      const themeName = localStorage.getItem("themeName")
      const userDepartment = JSON.parse(themeName as string)
      const colorConfigsName = userDepartment in appColorConfigs ? userDepartment : "default"
      return colorConfigsName
    }

    return appColorConfigs[themeName()]?.gradient || appColorConfigs.default.gradient
  }
}

export default new CustomTheme()
// export default theme
