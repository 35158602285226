import { linearGradient } from "polished"

const colorConfigs = {
  "Gray/Flat Button Bg": "#CBD0D1",
  "Text/Dark": "#B39E9A",
  "Text/Blue": "#0085CA",
  "Text/Primary Pink": "#E31C79",
  "Text/Gray": "#CBD0D1",
  "Text/Black2": "#2B2B2B",
  "Text/Gray Preview": "#606365",
  "Text/Gray Success": "#A2AAAD",
  "Gray/Card Border, Line": "#A2AAAD",
  "Text/Dark Grey": "#767676",
  "Other/Danger": "#F34336",
  "Other/Warning": "#FF9800",
  "Other Light/Danger": "#FCECE9",
  "Primary/Brown": "#805D57",
  "Dark Blue/Primary Text": "#003C71",
  //
  "Gray/Primary Text": "#2B2B2B",
  "Light Blue/Flat Button Background": "#E3F1F9",
  "Error/Error Text": "#FE7015",
  Success: "#74B62D",
  Warning: "#EEC11B",
  "Error/Line": "#F6A660",
  "Primary/Line": "#C80000",
  "Primary/Hover": "#AD0000",
  "Light /Button Background": "#FFE4E5",
  "Primary/Light Hover": "#FFCCCD",
  "Primary/Primary Text": "#C80000",
  "Gray/Bg": "#F5F6F6",
  "White / White": "#FFFFFF",
  "Primary/Background": "#C80000", //#EE2524  main
  "Text/Primary Text": "#000000",
  "Text/Secondary Text": "#2D2D2D",
  "Text/Secondary": "#7C7C7C",
  "Text/Line": "#7C7C7C",
  "Text/Placeholder": "#585858",
  "Text/Background": "#EAEAEA",
  "Approve / Waiting ": "#FFFFFF",
  "System/Warning Light": "#FFEDCC",
  "System/Error Light Hover": "#FFEBEB",
  "System/Info": "#005AE0",
  "System/Info Light": "#E0EDFF",
}

const backGroundColorGradient = linearGradient({
  colorStops: [`${colorConfigs["Primary/Primary Text"]} 81.24%`, `${colorConfigs["Error/Line"]} 96.64%`],
  toDirection: "213deg",
  fallback: colorConfigs["Primary/Primary Text"],
})

const colors = { colors: colorConfigs, gradient: backGroundColorGradient }

export default colors
