import { Box, Modal } from "components"
import { StatusTab } from "components/advance/StatusTab"
import { EnumClaimRequestStatus } from "constants/enums/claim-request-status"

import { compose, withFormik, withHooks, withStores } from "enhancers"
import { PageContent } from "layouts"
import { every, isEmpty } from "utils/lodash"
import styled from "styled-components"
import { checkShowPeriodSection, gql, paths } from "utils/helper"
import FilterClaimHistory from "./filterModal"
import ClaimHistoryTab from "./status-tab/HistoryTab"
import PeriodSection from "components/PeriodSection"
import { ShowDetailOn } from "constants/enums/show-detail-on"
import { env } from "configs"

const HistoryContainer = styled.div`
  margin-top: 24px;
`

const CustomHistoryTab = styled.div<{ tabName: string; selectedTab: string }>`
  display: ${(props) => (props.tabName === props.selectedTab ? "inline" : "none")};
`

const HistoryComponent = (props: any) => (
  <PageContent
    title="ประวัติการเบิก"
    type="secondary"
    titleCentered={false}
    onBack={props.handleClickBack}
    hasFilter={props.hasFilter}
    openFilterModal={props.openFilterModal}
    filterCount={props.filterCount}
    noScroll={true}
    showFilter={env.ENABLE_HISTORY_PAGE_FILTER}
  >
    <Box padding="16px" height="inherit">
      {props.isShowPeriodSection && (
        <PeriodSection mb="16px" textVariant="subtitle1" p="8px" textColor="Text/Secondary" />
      )}
      <StatusTab
        selectedStatus={props.selectedStatus}
        onClick={(status: EnumClaimRequestStatus) => props.handleChangeStatus(status)}
        isHistoryPage={true}
      />
      <HistoryContainer>
        {props.statusTab.map((status: EnumClaimRequestStatus) => (
          <CustomHistoryTab tabName={status} selectedTab={props.selectedStatus}>
            <ClaimHistoryTab status={status} externalCanSubmit={props.canSubmit} windowHeight={props.windowHeight} />
          </CustomHistoryTab>
        ))}
      </HistoryContainer>
    </Box>
  </PageContent>
)

const API = {
  GET_EMPLOYEE_OPTONS: gql`
    query GET_EMPLOYEE_OPTONS {
      getEmployeeOptions {
        label
        value
      }
    }
  `,
}

const enhancer = compose(
  withFormik({}),
  withStores((stores: any) => ({
    currentUserInfo: stores.userStore.currentUser,
    settingInfo: stores.settingStore.setting,
  })),
  withHooks((props: any, hooks: any) => {
    const { useQuery, useCallback, useEffect, useMemo, useState, useUrlParam } = hooks
    const { currentUserInfo, settingInfo } = props

    const [windowHeight, setWindowHeight] = useState(window.innerHeight)

    useEffect(() => {
      const handleResize = () => {
        setWindowHeight(window.innerHeight)
      }

      // Set up event listener for window resize
      window.addEventListener("resize", handleResize)

      // Clean up event listener on component unmount
      return () => {
        window.removeEventListener("resize", handleResize)
      }
    }, [])

    const currentUser = useMemo(() => {
      return currentUserInfo.employee
    }, [currentUserInfo])

    const isShowPeriodSection = useMemo(() => {
      if (!settingInfo) {
        return false
      }
      return checkShowPeriodSection(ShowDetailOn.ON_HISTORY_PAGE, settingInfo)
    }, [settingInfo])

    const queryParams = useUrlParam()
    const { tab, filters = {} } = queryParams

    const [canSubmit, setCanSubmit] = useState(true)
    const [hasFilter, setHasFilter] = useState(false)
    const [filterCount, setFilterCount] = useState(0)

    // const { data: employee, loading: loadingEmployee, error: errorEmployee } = useQuery(API.GET_EMPLOYEE_OPTONS, {
    //   fetchPolicy: "network-only",
    // })

    // const employeeOptions = useMemo(() => {
    //   if (loadingEmployee || errorEmployee) {
    //     return []
    //   }
    //   return employee?.getEmployeeOptions || []
    // }, [employee, loadingEmployee, errorEmployee])

    const refetchClaimHistory = useCallback(async () => {
      const filterValues = filters
      if (!isEmpty(filterValues)) {
        setHasFilter(true)
        setFilterCount(Object.values(filterValues).filter((item) => !!item).length)
      }
      if (every(filterValues, (value) => !value)) {
        setHasFilter(false)
        setFilterCount(0)
      }
    }, [filters])

    const openFilterModal = useCallback(() => {
      // @ts-ignore
      Modal.open({
        className: "FilterModal",
        children: <FilterClaimHistory employeeOptions={[]} initialValues={filters} />,
        cancelButtonLabel: "ปิดหน้าต่างนี้",
        cancelButtonVariant: "outlined",
        okButtonLabel: "ยืนยัน",
        okButtonVariant: "contained",
        onOk: async ({ close, values }: any) => {
          paths.historyPath({ tab: tab, filters: values }).push()
          setFilterCount(Object.values(values).filter((item) => !!item).length)
          setCanSubmit(true)
          // @ts-ignore
          Modal.close()
        },
      })
    }, [filters, tab])

    useEffect(() => {
      if (canSubmit) {
        const fetchData = () => {
          refetchClaimHistory()
          setCanSubmit(false)
        }
        fetchData()
      }
    }, [canSubmit, refetchClaimHistory])

    const handleClickBack = useCallback(() => {
      paths.landingPath().push()
    }, [])

    const handleChangeStatus = useCallback(
      (status: EnumClaimRequestStatus) => {
        paths.historyPath({ tab: status, filters: filters }).push()
      },
      [filters],
    )

    const infiniteScrollHeight = useMemo(() => {
      const height = windowHeight
      switch (height) {
        case height < 680:
          return 500
        default:
          return 780
      }
    }, [windowHeight])

    const statusTab = [
      EnumClaimRequestStatus.DRAFT,
      EnumClaimRequestStatus.WAITING,
      EnumClaimRequestStatus.APPROVED,
      EnumClaimRequestStatus.REJECTED,
      EnumClaimRequestStatus.CANCELED,
    ]

    return {
      handleClickBack,
      handleChangeStatus,
      selectedStatus: tab,
      currentUser,
      openFilterModal,
      hasFilter,
      filterCount,

      canSubmit,
      windowHeight: infiniteScrollHeight,

      statusTab,
      isShowPeriodSection,
    }
  }),
)

export default enhancer(HistoryComponent)
