// use for optimize weppack
import clone from "lodash/clone"
import cloneDeep from "lodash/cloneDeep"
import compact from "lodash/compact"
import every from "lodash/every"
import filter from "lodash/filter"
import find from "lodash/find"
import findKey from "lodash/findKey"
import first from "lodash/first"
import flatten from "lodash/flatten"
import forEach from "lodash/forEach"
import get from "lodash/get"
import has from "lodash/has"
import intersection from "lodash/intersection"
import isEmpty from "lodash/isEmpty"
import isEqual from "lodash/isEqual"
import isFunction from "lodash/isFunction"
import isNil from "lodash/isNil"
import isNumber from "lodash/isNumber"
import isString from "lodash/isString"
import isUndefined from "lodash/isUndefined"
import keys from "lodash/keys"
import last from "lodash/last"
import map from "lodash/map"
import mapValues from "lodash/mapValues"
import range from "lodash/range"
import replace from "lodash/replace"
import set from "lodash/set"
import sortBy from "lodash/sortBy"
import toString from "lodash/toString"
import uniq from "lodash/uniq"
import uniqBy from "lodash/uniqBy"
import values from "lodash/values"
import isArray from "lodash/isArray"
import times from "lodash/times"

export {
  clone,
  cloneDeep,
  compact,
  every,
  filter,
  find,
  findKey,
  first,
  flatten,
  forEach,
  get,
  has,
  intersection,
  isEmpty,
  isEqual,
  isFunction,
  isNil,
  isNumber,
  isString,
  isUndefined,
  keys,
  last,
  map,
  mapValues,
  range,
  replace,
  set,
  sortBy,
  toString,
  uniq,
  uniqBy,
  values,
  isArray,
  times,
}
