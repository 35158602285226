import { history } from "components/common/BrowserRouter"
import withHooks from "./withHooks"
import { AppColor } from "theme/app-color"
import { Box, Icon, Modal, Typography } from "components"

const withConfirmGoBack = (outerProps) =>
  withHooks((props, hooks) => {
    const { useEffect, useCallback, useRef, useUrlParam } = hooks

    const queryParams = useUrlParam()

    const confirmInfo = useRef(
      {
        enabled: true,
      },
      [],
    )

    const enableConfirm = useCallback(() => {
      confirmInfo.current = {
        ...confirmInfo.current,
        enabled: true,
      }
    }, [])

    const disableConfirm = useCallback(() => {
      confirmInfo.current = {
        ...confirmInfo.current,
        enabled: false,
      }
    }, [])

    useEffect(() => {
      const unblock = history.block((location) => {
        const { enabled } = confirmInfo.current
        if (enabled) {
          Modal.confirm({
            title: "",
            children: (
              <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <Box mb="16px">
                  <Icon fontSize="large" color={AppColor["Warning"]} name="Warning"></Icon>
                </Box>
                <Box mb="8px">
                  <Typography variant="h3" color={AppColor["Text/Primary Text"]}>
                    ต้องการออกจากหน้านี้หรือไม่
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body1" color={AppColor["Text/Primary Text"]}>
                    ข้อมูลทั้งหมดที่กรอกจะไม่ถูกบันทึก
                  </Typography>
                </Box>
                <Box mb="16px">
                  <Typography variant="body1" color={AppColor["Text/Primary Text"]}>
                    และไม่สามารถกู้คืนได้
                  </Typography>
                </Box>
              </Box>
            ),
            okButtonLabel: (
              <Box style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
                <Icon name="Check_circle" color={`${AppColor["Primary/Primary Text"]}`} />
                <Typography variant="body1">ออกจากหน้านี้</Typography>
              </Box>
            ),
            okButtonVariant: "outlined",
            cancelButtonLabel: (
              <Box style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
                <Icon name="cancel" />
                <Typography variant="body1">ยกเลิก</Typography>
              </Box>
            ),
            cancelButtonVariant: "contained",
            buttonWidth: 147,
            swapButton: true,
            onOk: async ({ close }) => {
              if (outerProps?.onOk) outerProps.onOk()
              await close()
              unblock()
              history.push(location)
            },
          })
          return false
        }
      })

      return () => {
        unblock()
      }
    }, [queryParams])

    return { ...props, enableConfirm, disableConfirm }
  })

export default withConfirmGoBack
