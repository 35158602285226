import { Box, Grid, Typography } from "components"
import { AppColor } from "theme/app-color"
import styled from "styled-components"
import { Label } from "./form-field/Label"
import { toCurrency } from "utils/helper"
import { isEmpty } from "utils/lodash"

const DetailContainer = styled(Box)`
  border-top: 1px solid ${AppColor["Gray/Flat Button Bg"]};
  margin-top: 8px;
  padding-top: 8px;
`

const SummaryCard = styled(Box)`
  padding: 8px;
  border: 1px solid ${AppColor["Gray/Flat Button Bg"]};
  border-radius: 4px;
  background-color: ${AppColor["White / White"]};
`

const checkTypeField = (values: any, field: any) => {
  return typeof values[field] === "string"
    ? values[field]
    : toCurrency(values[field], { maximumFractionDigits: 0, minimumFractionDigits: 0 })
}

export const SummaryComponent = (props: any) => {
  const { summary, values } = props

  return (
    <Grid container spacing={2} my={2}>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center">
          <Label title={"สรุปค่าใช้จ่าย"} icon="payments" iconColor={AppColor["Primary/Primary Text"]} />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <SummaryCard>
          <Box display="flex" flexDirection="column" padding="8px">
            {summary.total && (
              <Box display="flex" justifyContent="space-between">
                <Typography variant="body1" color="Text/Gray Preview">
                  {summary.total.label}
                </Typography>
                <Typography variant="h2" color="Primary/Primary Text">
                  {values[summary.total.field] === undefined ? "N/A" : checkTypeField(values, summary.total.field)}
                </Typography>
              </Box>
            )}
            {!isEmpty(summary.details) && (
              <DetailContainer>
                {summary.details.map((detail: any) => {
                  const value = values[detail.field] ? checkTypeField(values, detail.field) : "N/A"

                  return (
                    <Box display="flex" justifyContent="space-between" key={`${detail.label}`}>
                      <Typography variant="body1" color="Text/Gray Preview">
                        {detail.label}
                      </Typography>
                      <Typography variant="body1" color="Text/Gray Preview">
                        {value}
                      </Typography>
                    </Box>
                  )
                })}
              </DetailContainer>
            )}
          </Box>
        </SummaryCard>
      </Grid>
    </Grid>
  )
}
