import { Box, Grid, Typography } from "components"
import { Label } from "pages/main/claim-detail/form-field/Label"
import styled from "styled-components"
import { AppColor } from "theme/app-color"

const RejectReasonCard = styled(Box)`
  padding: 8px;
  border: 1px solid ${AppColor["Gray/Flat Button Bg"]};
  border-radius: 4px;
  background-color: ${AppColor["White / White"]};
`

export const RejectReasonComponent = (props: any) => {
  const { rejectedBy, rejectReason, remark } = props
  return (
    <>
      <Grid container spacing={2} mb={1} mt={8}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <Label title={"แจ้งตรวจสอบ"} icon="Warning" iconColor={AppColor["Primary/Primary Text"]} />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <RejectReasonCard>
            <Box display="flex" flexDirection="column" padding="8px">
              <Box display="flex" justifyContent="space-between" style={{ width: "100%", overflow: "hidden" }}>
                <Box display="flex">
                  <Typography variant="body1" color="Text/Gray Preview" style={{ lineHeight: "27px" }}>
                    ผู้ที่ขอให้ตรวจสอบ
                  </Typography>
                </Box>
                <Box display="flex" pl="8px">
                  <Typography variant="Body/16" color="Text/Primary Pink" style={{ textAlign: "right" }}>
                    {rejectedBy || "-"}
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" justifyContent="space-between" style={{ width: "100%", overflow: "hidden" }}>
                <Box display="flex">
                  <Typography variant="body1" color="Text/Gray Preview" style={{ lineHeight: "27px" }}>
                    เหตุผลที่ให้ตรวจสอบ
                  </Typography>
                </Box>
                <Box display="flex" pl="8px">
                  <Typography variant="Body/16" color="Text/Primary Pink" style={{ textAlign: "right" }}>
                    {rejectReason || "-"}
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" justifyContent="space-between" style={{ width: "100%", overflow: "hidden" }}>
                <Box display="flex">
                  <Typography variant="body1" color="Text/Gray Preview" style={{ lineHeight: "27px" }}>
                    หมายเหตุ
                  </Typography>
                </Box>
                <Box display="flex" pl="8px">
                  <Typography variant="Body/16" color="Text/Primary Pink" style={{ textAlign: "right" }}>
                    {remark || "-"}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </RejectReasonCard>
        </Grid>
      </Grid>
    </>
  )
}
