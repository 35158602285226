import styled from "styled-components"

import { DocumentConfig } from "./UploadDocumentCard"
import { Typography } from "components/common"
import { AppColor } from "theme/app-color"
import { isEmpty } from "utils/lodash"

const Container = styled.div`
  margin-top: 24px;
  margin-bottom: 40px;
`

const List = styled.ul`
  margin-top: 8px;
  margin-left: -16px;
`

export interface RequiredDocumentCardProps {
  documents: DocumentConfig[]
}

const getDescription = (description: any) => {
  return isEmpty(description) ? `` : `(${description})`
}

export const RequiredDocumentCard = (props: RequiredDocumentCardProps) => {
  const { documents } = props
  return (
    <Container>
      {documents.length > 0 && (
        <Typography variant="h3" color={AppColor["Text/Primary Text"]}>
          เอกสารที่ต้องใช้เบิก
        </Typography>
      )}
      <List>
        {documents.map((doc) => (
          <li key={`${doc.title}`}>
            <Typography variant="h4" color={AppColor["Text/Secondary Text"]}>
              {`${doc.title} ${getDescription(doc.description)}`}
            </Typography>
          </li>
        ))}
      </List>
    </Container>
  )
}
