import { compose, withHooks } from "enhancers"
import { Box, Typography, IconButton } from "components"
import { forceDownload } from "utils/helper"

import { ReactComponent as Download } from "assets/icon/download_brown.svg"
import PropTypes from "prop-types"

const FileUpload = (props) => (
  <Box display="flex" alignItems="center" {...props}>
    <Typography variant="body1" flex={1} color="Primary/Brown" noWrap>
      {props.fileName}
    </Typography>
    {!props.simple && (
      <Box display="flex">
        <IconButton color="inherit" onClick={props.download}>
          <Download />
        </IconButton>
      </Box>
    )}
  </Box>
)

const enhancer = compose(
  withHooks((props, hooks) => {
    const { useCallback, useEffect } = hooks
    const { field, form, simple, ...rest } = props

    const file = field.value
    const fileName = file?.name || file?.filename
    const download = useCallback(() => {
      if (file) {
        const url = URL.createObjectURL(file)
        forceDownload(url, fileName)
      }
    }, [file, fileName])

    const { url, filename } = file
    useEffect(() => {
      if (filename && url) {
        const loadFile = async () => {
          const blob = await fetch(url).then((r) => r.blob())
          const file = new File([blob], filename, { type: blob.type })
          form.setFieldValue(field.name, file)
        }
        loadFile()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url, filename])

    return {
      ...rest,
      fileName,
      download,
      simple,
    }
  }),
)

FileUpload.propTypes = {
  download: PropTypes.any.isRequired,
  fileName: PropTypes.any.isRequired,
  simple: PropTypes.any.isRequired,
}
export default enhancer(FileUpload)
