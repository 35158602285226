import { Typography } from "components"
import { compose, withHooks } from "enhancers"
import paths from "routes/paths"
import styled from "styled-components"
import { AppColor } from "theme/app-color"

const SectionBox = styled.div<{ mt: string; mb: string; p: string }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: ${AppColor["White / White"]};
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
  border: 1px solid #d4d4d4;
  border-radius: 8px;
  padding: ${(props) => props.p};
`

const DetailSpan = styled.span`
  color: ${AppColor["Primary/Primary Text"]};
  text-decoration: underline;
  cursor: pointer;
`

const PeriodSection = (props: any) => (
  <SectionBox mt={props.mt} mb={props.mb} p={props.p}>
    <Typography variant={props.textVariant} color={props.textColor}>
      {"ระยะเวลาในการพิจารณาคำร้องขึ้นอยู่กับวันที่ส่ง เรื่องเบิกและรอบการดำเนินงาน "}
      <DetailSpan onClick={props.onDetailClick}>ดูรายละเอียด</DetailSpan>
    </Typography>
  </SectionBox>
)

const enhancer = compose(
  withHooks((props: any, hooks: any) => {
    const { useCallback } = hooks
    const {
      mt = "",
      mb = "",
      p = "",
      textVariant = "body1",
      textColor = "Gray/Primary Text",
      disableConfirm,
      name,
      step,
      referenceId,
      createdDate,
      amount,
    } = props

    const onDetailClick = useCallback(() => {
      if (disableConfirm) {
        disableConfirm()
      }
      const params =
        name !== undefined &&
        step !== undefined &&
        referenceId !== undefined &&
        createdDate !== undefined &&
        amount !== undefined
          ? { name: name, step: step, referenceId: referenceId, createdDate: createdDate, amount: amount }
          : {}
      paths.considerationDetailPath(params).push()
    }, [amount, createdDate, disableConfirm, name, referenceId, step])

    return { onDetailClick, mt, mb, p, textVariant, textColor }
  }),
)

export default enhancer(PeriodSection)
