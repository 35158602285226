import { compose, withHooks } from "enhancers"
import { FormControlLabel, Typography } from "@material-ui/core"
import { Checkbox as FormikMuiCheckbox } from "formik-material-ui"

import PropTypes from "prop-types"
import { Icon } from "components/common"
import { AppColor } from "theme/app-color"

const Checkbox = ({ className, label, ...checkboxProps }) => (
  <FormControlLabel
    className={className}
    control={
      <FormikMuiCheckbox
        icon={<Icon name="CheckboxOutlineIconBox" />}
        checkedIcon={<Icon name="CheckboxOutlineCheckedIcon" color={AppColor["Primary/Background"]} />}
        size="small"
        indeterminate={false}
        style={{ marginLeft: 5 }}
        {...checkboxProps}
      />
    }
    label={
      <Typography variant="body1" style={{ fontSize: 16 }} align="center">
        {label}
      </Typography>
    }
  />
)

const enhancer = compose(
  withHooks((props) => {
    return { ...props }
  }),
)

Checkbox.propTypes = {
  className: PropTypes.any.isRequired,
  label: PropTypes.any.isRequired,
}

export default enhancer(Checkbox)
