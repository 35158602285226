import { Dialog as MuiDialog, DialogContent, CircularProgress } from "@material-ui/core"
import { Box, Typography } from "components"
import { compose, withFormik, withHooks } from "enhancers"
import styled from "styled-components"

const Dialog = styled(MuiDialog)`
  .MuiDialog-paperScrollPaper {
    padding: 0;
    margin: 1vw;
    min-width: 343px;
    overflow: hidden;
  }
`

const ModalContainer = styled(DialogContent)`
  height: 100%;
  padding: 16px;
`

const ContentContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const TitleContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0px auto;
  margin: 16px 0px 16px 0px;
`

const LoadingModalComponent = (props: any) => {
  return (
    <Dialog open={props.isOpen}>
      <ModalContainer>
        <ContentContainer>
          <CircularProgress color="primary" />
          <TitleContainer>
            <Typography variant="h3" color="Text/Primary Text">
              {props.title}
            </Typography>
          </TitleContainer>
          <Box mb="4px">
            <Typography variant="body1" color="Text/Primary Text">
              กรุณารอสักครู่
            </Typography>
          </Box>
        </ContentContainer>
      </ModalContainer>
    </Dialog>
  )
}

const enhancer = compose(
  withFormik({}),
  withHooks((props: any, hooks: any) => {
    const { isOpen, title = "กำลังโหลดข้อมูล" } = props

    return {
      isOpen,
      title,
    }
  }),
)

const LoadingModal = enhancer(LoadingModalComponent)

export default LoadingModal
