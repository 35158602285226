import { compose, defaultProps, withHooks } from "enhancers"
import { Box } from "components"
import { DatePicker as FormikMuiDatePicker } from "formik-material-ui-pickers"
import { format } from "date-fns"
import { get } from "utils/lodash"

import { ReactComponent as DatePickerIcon } from "assets/icon/datepicker_icon.svg"

import { AppColor } from "theme/app-color"
import styled, { createGlobalStyle } from "styled-components"
// const CustomFormikMuiDatePicker = styled(FormikMuiDatePicker)`
//   .MuiPickersToolbar-root {
//     background-color: ${AppColor["Primary/Primary Text"]};
//   }
//   &.MuiPickersDay-daySelected {
//     background-color: ${AppColor["Primary/Primary Text"]} !important;
//     color: #fff !important; /* Set the text color of the selected day */
//   }
//   &.MuiPickersDay-dayDisabled {
//     color: rgba(0, 0, 0, 0.38) !important; /* Set color for disabled days */
//   }
//   &.toolbar {
//     background-color: #c80000;
//   }
// `

const GlobalStyleDatePicker = createGlobalStyle<{ color?: string; hover?: string }>`
  .MuiPickersToolbarButton-toolbarBtn {
    height: auto;
  }
  .MuiPickersToolbar-toolbar {
    background-color: ${({ color }) => color || "#c80000"};
  }

  .MuiPickersDay-daySelected {
    background-color: ${({ color }) => color || "#c80000"};
    color: #ffffff !important;
    &:hover {
      background-color: ${({ color }) => color || "#c80000"};
    }
  }

  .MuiPickersDay-current {
    color: ${({ color }) => color || "#c80000"};
  }

  .MuiButton-containedPrimary {
    color: #fff;
    background-color: ${({ color }) => color || "#c80000"};
  }

  .MuiButton-containedPrimary:hover {
    background-color: ${({ hover }) => hover || "#ad0000"};
  }
`

const CustomFormikMuiDatePicker = styled(FormikMuiDatePicker)``

const DatePicker = (props: any) => (
  <Box width={props.width} position="relative">
    <GlobalStyleDatePicker color={AppColor["Primary/Primary Text"]} hover={AppColor["Primary/Hover"]} />
    <CustomFormikMuiDatePicker {...props} />
    <Box position="absolute" top={12} right={12}>
      <DatePickerIcon />
    </Box>
  </Box>
)

const enhancer = compose(
  defaultProps({ format: "dd/MM/yyyy", placeholder: "วันที่" }),
  withHooks(
    (
      props: {
        form: { touched: any; submitCount: number; errors: any }
        field: { name: any; value: any }
        format: string
      },
      hooks: { useMemo: any },
    ) => {
      const { useMemo } = hooks

      const error = useMemo(() => {
        const touched = get(props.form.touched, props.field.name) || props.form.submitCount > 0
        const error = get(props.form.errors, props.field.name)
        return touched && !!error
      }, [props.form, props.field])

      return {
        ...props,
        error,
        labelFunc: (date: number | Date, invalidLabel: any) => (props.field.value ? format(date, props.format) : ""),
      }
    },
  ),
)

export default enhancer(DatePicker)
