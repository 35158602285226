import { useMsal } from "@azure/msal-react"
import { DialogActions, DialogContent, Dialog as MuiDialog } from "@material-ui/core"
import { Box, Button, Icon, Typography } from "components"
import { compose, withFormik, withHooks } from "enhancers"
import styled from "styled-components"
import { AppColor } from "theme/app-color"
import { gql, paths } from "utils/helper"
import { removeToken } from "api"

const Dialog = styled(MuiDialog)`
  .MuiDialog-paperScrollPaper {
    padding: 0;
    margin: 1vw;
    min-width: 343px;
    overflow: auto;
  }
`

const ModalContainer = styled(DialogContent)`
  height: 100%;
  padding: 16px;
`

const ContentContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const TitleContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0px auto;
  margin: 16px 0px 16px 0px;
`

const ActionContainer = styled(Box)`
  display: flex;
  width: 100%;
  padding: 0px 16px 16px 16px;
`

const ActionButton = styled(Button)`
  width: 100%;
`

interface LogoutModalProps {
  isOpen: boolean
  handleClose: () => void
  handleConfirm: () => void
  handleChangeValue: () => void
  handleChangeCheck: () => void
}

const LogoutModalComponent = (props: LogoutModalProps) => {
  return (
    <Dialog open={props.isOpen}>
      <ModalContainer>
        <ContentContainer>
          <Icon width="40px" height="40px" name="ExitToApp" color={AppColor["Other/Warning"]} />
          <TitleContainer>
            <Typography variant="h3" color="Text/Primary Text">
              ออกจากระบบ
            </Typography>
          </TitleContainer>
          <Box mb="4px">
            <Typography variant="body1" color="Text/Primary Text">
              คุณต้องการออกจากระบบหรือไม่
            </Typography>
          </Box>
        </ContentContainer>
      </ModalContainer>
      <DialogActions>
        <ActionContainer>
          <ActionButton onClick={props.handleClose} mr="16px" variant="outlined">
            ยกเลิก
          </ActionButton>
          <ActionButton variant="contained" onClick={props.handleConfirm}>
            ออกจากระบบ
          </ActionButton>
        </ActionContainer>
      </DialogActions>
    </Dialog>
  )
}

export const API = {
  LOGOUT: gql`
    mutation LOGOUT {
      logout {
        employee {
          employeeCode
          firstName
          lastName
        }
      }
    }
  `,
}

const enhancer = compose(
  withFormik({}),
  withHooks((props: any, hooks: any) => {
    const { useMutation, useMemo, useCallback } = hooks
    const { isOpen, onClose } = props

    const { instance, accounts, inProgress } = useMsal()

    const [logout] = useMutation(
      API.LOGOUT,
      {
        fetchPolicy: "network-only",
        onCompleted: (data: any) => {
          removeToken()
          handleClose()
          window.location.href = paths.signInPath()
        },
        onError: (data: any) => {
          console.log("LOGOUT ERROR : ", data)
        },
      },
      [],
    )

    const hasAccount = useMemo(() => {
      return accounts.length > 0
    }, [instance, accounts, inProgress])

    const handleClose = useCallback(() => {
      onClose()
    }, [onClose])

    const handleClickLogout = useCallback(() => {
      if (hasAccount) {
        instance.logout({
          account: accounts[0],
          onRedirectNavigate: (url) => {
            instance.setActiveAccount(null)
            removeToken()
            handleClose()
            window.location.href = paths.signInPath()
            return true
          },
        })
        instance.setActiveAccount(null)
      } else {
        logout()
      }
    }, [hasAccount, instance, accounts])

    const handleConfirm = useCallback(() => {
      handleClickLogout()
    }, [onClose, instance, accounts])

    return {
      isOpen,
      handleClose,
      handleConfirm,
    }
  }),
)

const LogoutModal = enhancer(LogoutModalComponent)

export default LogoutModal
