import { useMemo } from "react"
import { useLocation } from "react-router-dom"
import qs from "qs"
import humps from "humps"
import { forEach, isUndefined } from "utils/lodash"

const useUrlParam = (config) => {
  const { search } = useLocation()

  const params = useMemo(() => {
    const temp = humps.camelizeKeys(qs.parse(search.replace(/^\?/, "")))
    forEach(config, (type, fieldName) => {
      const value = temp[fieldName]
      if (!isUndefined(value)) {
        temp[fieldName] = (() => {
          switch (type) {
            case "boolean":
              return value === "true"
            // case "array[string]":
            //   return value;
            default:
              return value
          }
        })()
      }
    })
    return temp
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  return params
}

export default useUrlParam
