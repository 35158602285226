// client กับ server ต้องมีของเท่ากัน

export const ReportType = {
  // "Claim Requests Report": null,
  รายการอนุมัติการเบิก: null,
  Eclaim: null,
  AllPay: null,
  Tax: null,
  // "Allowance Requisition": null,
  // "Domestic Non Tax": { request: "domestic", transCode: 507 },
  // "Domestic Cal Tax": { request: "domestic", transCode: 111 },
  // "International Non Tax": { request: "internationalTripAllowance", transCode: 510 },
  // "First Trip": { request: "firstTripAllowance", transCode: 166 },
}
