import { Box, Button, Grid, Link, Icon, Typography } from "components"
import { ClaimCard } from "components/advance/ClaimCard"
import PeriodSection from "components/PeriodSection"
import { compose, withHooks } from "enhancers"
import styled from "styled-components"
import { AppColor } from "theme/app-color"

const SectionHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
`
const CustomIconList = styled(Icon)`
  font-size: 20px;
  margin-left: 8px;
`

const NotifyBox = styled(Box)`
  display: flex;
  margin-top: 16px;
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 8px;
  background: ${AppColor["System/Info Light"]};
`

const ShowHighlightRequests = (props: any) => (
  <>
    <SectionHeader style={{ display: "flex" }}>
      <Box mr="8px">
        <Typography variant="h3" color="Text/Primary Text">
          สวัสดิการพนักงาน
        </Typography>
      </Box>
      <Box mt="2px">
        <Typography variant="subtitle1" color="Text/Primary Text">
          ( {props.length} รายการ )
        </Typography>
      </Box>
    </SectionHeader>

    {props.validateErrorText && (
      <NotifyBox>
        <Icon
          fontSize="small"
          color={AppColor["System/Info"]}
          name="Info"
          style={{ width: "16px", height: "16px", marginRight: "4px" }}
        ></Icon>
        <Typography variant="Body/14">{props.validateErrorText}</Typography>
      </NotifyBox>
    )}
    {props.notifyBeforeStartAtText && (
      <NotifyBox>
        <Icon
          fontSize="small"
          color={AppColor["System/Info"]}
          name="Info"
          style={{ width: "16px", height: "16px", marginRight: "4px" }}
        ></Icon>
        <Typography variant="Body/14">{props.notifyBeforeStartAtText}</Typography>
      </NotifyBox>
    )}
    {props.isShowPeriodSection && <PeriodSection mt="16px" mb="8px" p="8px" textColor="Text/Line" isHaveBorder />}

    <Grid container>
      <Grid container mt={4}>
        {props.claimRequestConfigs.map((data: any, ind: number) => {
          const request = props.claimRequests[data.name]
          const budgetValues = props.budgets[data.name]?.list || {}
          return (
            <ClaimCard
              key={data.name}
              onClick={() => props.handleClickRequest(data)}
              type={data.type}
              name={data.name}
              approvedRequest={data.approvedCount}
              totalAmount={data.approvedAmount}
              title={data.title}
              icon={data.icon}
              iconColor={data.iconColor}
              chipTitleColor={data.chipTitleColor}
              chipBackgroundColor={data.chipBackgroundColor}
              budget={request.budget ? request.budget.list : null}
              budgetValues={budgetValues}
              yearlyBudget={request.type === "ค่ารักษาพยาบาล" ? props.yearlyBudget : {}}
              fullSize
            />
          )
        })}
      </Grid>
    </Grid>
    {props.showButtonSeeAll && (
      <Link to="/claim-list" style={{ textDecoration: "none" }}>
        <Button width="100%" variant="outlined">
          สวัสดิการทั้งหมด
          {<CustomIconList name="ChevronRight" />}
        </Button>
      </Link>
    )}
  </>
)

const enhancer = compose(
  withHooks((props: any, hooks: any) => {
    return { ...props }
  }),
)

export default enhancer(ShowHighlightRequests)
