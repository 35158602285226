import { gql, useLazyQuery } from "@apollo/client"
import { ReactComponent as ExportFile } from "assets/icon/export_file.svg"
import FilterAltIcon from "assets/icon/filter_alt.svg"
import {
  Box,
  Button,
  DatePicker,
  ExcelGenerator,
  Field,
  Form,
  Grid,
  Icon,
  Select,
  Table,
  TextField,
  Typography,
} from "components"
import { EnumClaimRequestStatus } from "constants/enums/claim-request-status"
import { ReportType } from "constants/objects/report-type"
import { compose, withFormik, withHooks, withStores } from "enhancers"
import { PageContent, PageFooter } from "layouts"
import styled from "styled-components"
import { AppColor } from "theme/app-color"
import { employeeIsHr, formatDate, paths, toCurrency } from "utils/helper"
import { ReportFilterModal } from "./ReportFilterModal"
import { isEmpty, isNumber } from "utils/lodash"

const Title = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const FilterBox = styled(Box)`
  margin-top: 24px;
  margin-bottom: 24px;
  background: ${AppColor["White / White"]};
  border-radius: 8px;
  padding: 16px;
  border: 1px solid ${AppColor["Text/Background"]};
`

const ResetFilterButton = styled(Box)`
  display: flex;
  align-items: center;
  text-decoration: underline;
  color: ${AppColor["Primary/Primary Text"]};
  :hover {
    cursor: pointer;
  }
`

const ClaimTable = styled(Table)<{}>(() => ({
  backgroundColor: AppColor["White / White"],
  "& .MuiDataGrid-columnHeader": {
    background: AppColor["Primary/Background"],
    borderRight: `1px solid ${AppColor["Primary/Primary Text"]}`,
  },
  "& .MuiDataGrid-columnsContainer": {
    border: `1px solid ${AppColor["Primary/Primary Text"]}`,
    borderRadius: "8px 8px 0px 0px",
    pointerEvents: "none",
  },
  "& .MuiDataGrid-dataContainer": {
    borderRight: `1px solid ${AppColor["Gray/Card Border, Line"]}`,
  },
  "& .MuiDataGrid-row": {
    borderTop: `1px solid ${AppColor["Primary/Primary Text"]}`,
    borderLeft: `1px solid ${AppColor["Gray/Card Border, Line"]}`,
    borderRight: `1px solid ${AppColor["Gray/Card Border, Line"]}`,
    borderBottom: `1px solid ${AppColor["Gray/Card Border, Line"]}`,
  },
  "& .MuiDataGrid-cell": {
    borderRight: `1px solid ${AppColor["Gray/Card Border, Line"]}`,
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-sortIcon": {
    display: "none",
  },
  "& .MuiTablePagination-root": {
    color: AppColor["Text/Primary Text"],
  },
  "& .MuiTablePagination-select.MuiSelect-selectMenu.MuiInputBase-input": {
    border: `1px solid ${AppColor["Text/Line"]}`,
  },
  "& .MuiTablePagination-actions": {
    marginLeft: "16px",
  },
  "& .MuiIconButton-root": {
    padding: "8px",
  },
  "& .MuiTypography-root": {
    fontWeight: 500,
  },
}))

const DashboardPage = (props: any) => (
  <>
    <PageContent
      title="E-claim"
      titleCentered={false}
      onListClickTo="/claim-requests"
      showHomeIcon
      overlapHeight="200px"
      extendedTitleHeight="180px"
    >
      <Box padding="16px">
        <Title mt="8px">
          <Typography variant="h1" color={AppColor["White / White"]}>
            รายการขอเบิก
          </Typography>
          <Box style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end" }}>
            <Button
              color={AppColor["White / White"]}
              width="176px"
              variant="contained"
              style={{ border: "none" }}
              onClick={props.handleOpenExportReportModal}
            >
              <Icon name="report" />
              <Typography variant="body1" style={{ marginLeft: "6px" }}>
                ออกรายงาน
              </Typography>
            </Button>
          </Box>
        </Title>
        <FilterBox>
          <Form>
            <Title mb="16px">
              <Box display="flex" alignItems="center">
                <img src={FilterAltIcon} style={{ width: "19px", height: "19px", marginRight: "8px" }} alt="" />
                <Typography variant="h4" color={AppColor["Text/Primary Text"]}>
                  ตัวกรอง
                </Typography>
              </Box>
            </Title>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <Box style={{ marginBottom: "4px" }} mb={2}>
                  <Typography variant="body1">ช่วงวันที่สร้างรายการ</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Box>
                    <Field
                      component={DatePicker}
                      name="startDate"
                      placeholder="วันที่"
                      fullWidth
                      maxDate={props.values.endDate}
                      onChange={(values: string) => props.setFieldValue("startDate", values)}
                    />
                  </Box>
                  <Box padding="0px 8px">
                    <Typography variant="body1" color={AppColor["Text/Primary Text"]}>
                      -
                    </Typography>
                  </Box>
                  <Box>
                    <Field
                      component={DatePicker}
                      name="endDate"
                      placeholder="วันที่"
                      fullWidth
                      minDate={props.values.startDate}
                      onChange={(values: string) => props.setFieldValue("endDate", values)}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <Box mb={2}>
                      <Typography variant="body1">สถานะ</Typography>
                    </Box>
                    <Field
                      component={Select}
                      name="status"
                      type="text"
                      placeholder="สถานะ"
                      options={props.claimStatusOptions}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Box mb={2}>
                      <Typography variant="body1">เลขอ้างอิง</Typography>
                    </Box>
                    <Field component={TextField} name="referenceId" fullWidth />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={4} mt="8px">
              <Grid item xs={12} sm={6}>
                <Box mb={2}>
                  <Typography variant="body1">ประเภทการเบิก</Typography>
                </Box>
                <Field
                  component={Select}
                  name="claimTitle"
                  type="text"
                  placeholder="ประเภทการเบิก"
                  options={props.claimTitleOptions}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box mb={2}>
                  <Typography variant="body1">ผู้ขอเบิก</Typography>
                </Box>
                <Field
                  component={Select}
                  name="employeeId"
                  type="text"
                  placeholder="ผู้ขอเบิก"
                  options={props.employeeOptions}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={4} mt="8px">
              <Grid item xs={12} sm={6}></Grid>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={4} alignItems="center">
                  <Grid item xs={6}>
                    <Box display="flex" justifyContent="center" alignItems="center">
                      <ResetFilterButton onClick={props.clearFilter}>
                        <Typography variant="h4" color={AppColor["Primary/Primary Text"]}>
                          รีเซ็ตตัวกรอง
                        </Typography>
                      </ResetFilterButton>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      color={AppColor["Primary/Primary Text"]}
                      variant="outlined"
                      width="100%"
                      onClick={props.handleFilter}
                    >
                      กรอง
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </FilterBox>
        <ClaimTable
          className="claimRequestTable"
          columns={props.columns}
          rows={props.tableData}
          loading={props.loading}
          density="compact"
          autoHeight
          autoDetectInitialFilterOnUrlQueryParams
          onRowClick={props.goToHistoryDetail}
          pageSize={props.pageSize}
          onPageSizeChange={props.onPageSizeChange}
          rowsPerPageOptions={[10, 25, 50]}
        />
      </Box>
    </PageContent>
    <ReportFilterModal
      isOpen={props.isExportReportModalOpen}
      onConfirm={props.handleClickExportReport}
      onCancel={props.handleCloseExportReportModal}
      defaultStartDate={props.startDate}
      defaultEndDate={props.endDate}
    />
  </>
)

const API = {
  GET_CLAIM_REQUEST_CONFIGS: gql`
    query GET_CLAIM_REQUEST_CONFIGS($employeeId: String!) {
      claimRequestConfigs(employeeId: $employeeId) {
        requests
      }
    }
  `,
  GET_EMPLOYEES: gql`
    query GET_EMPLOYEES {
      getEmployees {
        id
        employeeCode
        firstName
        lastName
      }
    }
  `,
  GET_DASHBOARD_CLAIM_LIST: gql`
    query GET_DASHBOARD_CLAIM_LIST($filters: JSON) {
      dashboardClaimList(input: { filters: $filters }) {
        id
        referenceId
        employee
        status
        config
        createdAt
        transferDate
      }
    }
  `,
  GET_PREPARE_REPORT_DATA: gql`
    query GET_PREPARE_REPORT_DATA($reportType: String!, $startDate: String!, $endDate: String!) {
      prepareReportData(filters: { reportType: $reportType, startDate: $startDate, endDate: $endDate })
    }
  `,
}

const today = new Date(Date.now())

const enhancer = compose(
  withFormik({
    mapPropsToValues: () => ({
      startDate: new Date(today.getFullYear(), today.getMonth(), 1),
      endDate: today,
      claimTitle: "all",
      status: "ALL",
    }),
  }),
  withStores((stores: any) => ({
    currentUserInfo: stores.userStore.currentUser,
  })),
  withHooks((props: any, hooks: any) => {
    const { currentUserInfo, values, setValues, setFieldValue } = props
    const { useMemo, useCallback, useQuery, useState } = hooks
    const [claimTitleOptions, setClaimTitleOptions] = useState([])
    const [employeeOptions, setEmployeeOptions] = useState([])
    const [pageSize, setPageSize] = useState(10)
    const [isExportReportModalOpen, setIsExportReportModalOpen] = useState(false)

    const [reportType, setReportType] = useState()
    const { loading, data, error, refetch } = useQuery(API.GET_DASHBOARD_CLAIM_LIST, {
      variables: {
        filters: {
          startDate: formatDate(new Date(today.getFullYear(), today.getMonth(), 1), "yyyy-MM-dd"),
          endDate: formatDate(today, "yyyy-MM-dd"),
          claimTitle: "all",
          status: "ALL",
        },
      },
      fetchPolicy: "network-only",
    })

    const [fetchExportData] = useLazyQuery(API.GET_PREPARE_REPORT_DATA, {
      onCompleted: (data) => {
        exportReport(data)
      },
      fetchPolicy: "network-only",
    })

    useQuery(API.GET_EMPLOYEES, {
      onCompleted: (data: any) => {
        const current = data.getEmployees

        const employees: any = []
        current.forEach((item: any) => {
          employees.push({
            label: item.firstName + " " + item.lastName,
            value: item.id,
          })
        })

        setEmployeeOptions(employees)
      },
      fetchPolicy: "network-only",
    })

    useQuery(API.GET_CLAIM_REQUEST_CONFIGS, {
      variables: {
        employeeId: currentUserInfo.employee.id,
      },
      onCompleted: (data: any) => {
        const current = data.claimRequestConfigs.requests

        const claimTitle: any = []
        claimTitle.push({
          label: "ทั้งหมด",
          value: "all",
        })
        current.forEach((item: any) => {
          claimTitle.push({
            label: item.title,
            value: item.title,
          })
        })

        setClaimTitleOptions(claimTitle)
      },
      fetchPolicy: "network-only",
    })

    const columns = useMemo(() => {
      return [
        { width: 140, field: "referenceId", headerName: "เลขทำรายการ" },
        { width: 220, field: "name", headerName: "ผู้ขอเบิก" },
        {
          width: 220,
          field: "claimTitle",
          headerName: "ประเภทการเบิก",
        },
        { width: 105, field: "status", headerName: "สถานะ" },
        {
          width: 120,
          field: "createdAt",
          headerName: "วันที่สร้าง",
          type: "dateOnly",
        },
        {
          width: 100,
          field: "transferDate",
          headerName: "วันที่โอนเงินให้กับพนักงาน",
          type: "dateOnly",
        },
      ]
    }, [])

    const MappingStatusLabel = (status: EnumClaimRequestStatus) => {
      switch (status) {
        case EnumClaimRequestStatus.DRAFT:
          return "แบบร่าง"
        case EnumClaimRequestStatus.APPROVED:
          return "อนุมัติ"
        case EnumClaimRequestStatus.WAITING:
          return "รออนุมัติ"
        case EnumClaimRequestStatus.REJECTED:
          return "ไม่อนุมัติ"
        case EnumClaimRequestStatus.CANCELED:
          return "ยกเลิก"
        default:
          break
      }
    }

    const tableData = useMemo(() => {
      if (loading || error) {
        return []
      }
      return data?.dashboardClaimList.map((claim: any) => {
        const { id, referenceId, employee, status, config, createdAt, transferDate } = claim

        const name = employee.firstName + " " + employee.lastName

        const claimStatus = MappingStatusLabel(status)

        return {
          id,
          referenceId,
          name,
          status: claimStatus,
          claimTitle: config.title,
          createdAt,
          transferDate,
        }
      })
    }, [loading, data, error])

    const claimStatusOptions = useMemo(() => {
      return [
        {
          label: "ทั้งหมด",
          value: "ALL",
        },
        {
          label: "รออนุมัติ",
          value: EnumClaimRequestStatus.WAITING,
        },
        {
          label: "อนุมัติ",
          value: EnumClaimRequestStatus.APPROVED,
        },
        {
          label: "ไม่อนุมัติ",
          value: EnumClaimRequestStatus.REJECTED,
        },
        {
          label: "ยกเลิก",
          value: EnumClaimRequestStatus.CANCELED,
        },
      ]
    }, [])

    const handleFilter = useCallback(async () => {
      const variables = {
        filters: {
          claimType: values.claimTitle,
          startDate: formatDate(values.startDate, "yyyy-MM-dd"),
          endDate: formatDate(values.endDate, "yyyy-MM-dd"),
          status: values.status,
          referenceId: values.referenceId,
          employeeId: values.employeeId,
        },
      }
      console.log(variables)
      await refetch(variables)
    }, [values, refetch])

    const clearFilter = useCallback(async () => {
      const variables = {
        filters: {
          claimType: "all",
          startDate: formatDate(new Date(today.getFullYear(), today.getMonth(), 1), "yyyy-MM-dd"),
          endDate: formatDate(today, "yyyy-MM-dd"),
          status: "ALL",
        },
      }
      await refetch(variables)
      setValues({
        claimTitle: "all",
        startDate: new Date(today.getFullYear(), today.getMonth(), 1),
        endDate: today,
        status: "ALL",
        referenceId: "",
        employeeId: null,
      })
    }, [setValues, refetch])

    const goToHistoryDetail = useCallback((params: any) => {
      const { id } = params
      paths.dashboardDetailPath(id).push()
    }, [])

    const onPageSizeChange = useCallback((newPage: any) => setPageSize(newPage), [])

    const userIsHr = useMemo(() => employeeIsHr(currentUserInfo.employee.role), [currentUserInfo])

    const handleOpenExportReportModal = useCallback(() => {
      setIsExportReportModalOpen(true)
    }, [])

    const handleCloseExportReportModal = useCallback(() => {
      setIsExportReportModalOpen(false)
    }, [])

    const exportBLCPReport = useCallback(
      (data: any) => {
        // @ts-ignore
        ExcelGenerator.generate({
          fileName: `${reportType}-${formatDate(new Date(), "yyyyMMddhhmmss")}`,
          columns: [
            {
              field: "no",
              title: "No",
              width: "30px",
            },
            {
              field: "employeeCode",
              title: "Employee Code",
              width: "150px",
            },
            {
              field: "employeeName",
              title: "Employee Name",
              width: "300px",
            },
            {
              field: "type",
              title: "Claim Name",
              width: "350px",
            },
            {
              field: "status",
              title: "Status",
              width: "100px",
            },
            {
              field: "amount",
              title: "Amount",
              width: "100px",
            },
            {
              field: "remark",
              title: "Remark",
              width: "300px",
            },
          ],
          data,
        })
      },
      [reportType],
    )
    const exportEclaimReport = useCallback(
      (data: any) => {
        // @ts-ignore
        ExcelGenerator.generate({
          fileName: `${reportType}-${formatDate(new Date(), "yyyyMMddhhmmss")}`,
          columns: [
            {
              field: "no",
              title: "No",
              width: "30px",
            },
            {
              field: "companyName",
              title: "Emp Id",
              width: "150px",
            },
            {
              field: "employeeID",
              title: "Employee Name",
              width: "300px",
            },
            {
              field: "employeeName",
              title: "Employee Name",
              width: "300px",
            },
            {
              field: "goordReceiver",
              title: "Good Receiver",
              width: "350px",
            },
            {
              field: "claimTopic",
              title: "Claim Topic",
              width: "350px",
            },
            {
              field: "amount",
              title: "Amount",
              width: "100px",
            },
            {
              field: "amount",
              title: "Document Date",
              width: "200px",
            },
            {
              field: "claimDate",
              title: "Claim Date",
              width: "200px",
            },
            {
              field: "status",
              title: "Status",
              width: "100px",
            },
            {
              field: "hrApprove",
              title: "HR Approve Date",
              width: "200px",
            },
            {
              field: "remark",
              title: "Remark",
              width: "300px",
            },
            {
              field: "allPayStatus",
              title: "All Pay Status",
              width: "300px",
            },
            {
              field: "allPayDoc",
              title: "All Pay Doc.",
              width: "300px",
            },
            {
              field: "allPayReason",
              title: "allPayReason",
              width: "300px",
            },
            {
              field: "allPayRemark",
              title: "allPayRemark",
              width: "300px",
            },
            {
              field: "allPayPaymentDate",
              title: "allPayPaymentDate",
              width: "300px",
            },
          ],
          data,
        })
      },
      [reportType],
    )
    const exportTaxReport = useCallback(
      (data: any) => {
        // @ts-ignore
        ExcelGenerator.generate({
          fileName: `${reportType}-${formatDate(new Date(), "yyyyMMddhhmmss")}`,
          columns: [
            {
              field: "no",
              title: "No",
              width: "30px",
            },
            {
              field: "employeeName",
              title: "ชื่่อ-สกุล พนักงาน",
              width: "150px",
            },
            {
              field: "employeeId",
              title: "เลขประจำตัว",
              width: "300px",
            },
            {
              field: "companyCode",
              title: "รหัสบริษัท",
              width: "350px",
            },
            {
              field: "date",
              title: "วันที่ Stamp",
              width: "100px",
            },
            {
              field: "transferDate",
              title: "วันที่จ่ายจริง",
              width: "100px",
            },
            {
              field: "allPayCode",
              title: "หมายเลข Allpay",
              width: "300px",
            },
            {
              field: "typePoint",
              title: "ประเภทการใช้ point",
              width: "300px",
            },
            {
              field: "amount",
              title: "จำนวนเงิน",
              width: "300px",
            },
          ],
          data,
        })
      },
      [reportType],
    )
    const exportAllPayReport = useCallback(
      (data: any) => {
        // @ts-ignore
        ExcelGenerator.generate({
          fileName: `${reportType}-${formatDate(new Date(), "yyyyMMddhhmmss")}`,
          columns: [
            {
              field: "no",
              title: "Document No.",
              width: "30px",
            },
            {
              field: "company",
              title: "Company",
              width: "30px",
            },
            {
              field: "companyName",
              title: "Company Name",
              width: "150px",
            },
            {
              field: "scgEmployeeID",
              title: "SCG EMP ID",
              width: "300px",
            },
            {
              field: "employeeName",
              title: "Employee Name",
              width: "350px",
            },
            {
              field: "claimDetail",
              title: "Claim Detail",
              width: "100px",
            },
            {
              field: "costCenter",
              title: "Cost Center",
              width: "100px",
            },
            {
              field: "costElement",
              title: "costElement",
              width: "300px",
            },
            {
              field: "totalExpense",
              title: "Total Expense",
              width: "300px",
            },
            {
              field: "requestDate",
              title: "requestDate",
              width: "300px",
            },
            {
              field: "claimStatus",
              title: "claimStatus",
              width: "300px",
            },
            {
              field: "approveDate",
              title: "approveDate",
              width: "300px",
            },
            {
              field: "tax",
              title: "Tax",
              width: "50px",
            },
          ],
          data,
        })
      },
      [reportType],
    )
    const exportReport = useCallback(
      (data: any) => {
        console.log(reportType)
        switch (reportType) {
          case "รายการอนุมัติการเบิก":
            exportBLCPReport(
              data.prepareReportData.map((reportData: any) => ({
                ...reportData,
                status: MappingStatusLabel(reportData.status),
                amount: isNumber(reportData.amount)
                  ? toCurrency(reportData.amount, { maximumFractionDigits: 2, minimumFractionDigits: 2 })
                  : "",
              })),
            )
            break
          case "Eclaim":
            exportEclaimReport(
              data.prepareReportData.map((reportData: any) => ({
                ...reportData,
                status: MappingStatusLabel(reportData.status),
                amount: isNumber(reportData.amount)
                  ? toCurrency(reportData.amount, { maximumFractionDigits: 2, minimumFractionDigits: 2 })
                  : "",
              })),
            )
            break
          case "Tax":
            exportTaxReport(
              data.prepareReportData.map((reportData: any) => ({
                ...reportData,
                status: MappingStatusLabel(reportData.status),
                amount: isNumber(reportData.amount)
                  ? toCurrency(reportData.amount, { maximumFractionDigits: 2, minimumFractionDigits: 2 })
                  : "",
              })),
            )
            break
          case "AllPay":
            exportAllPayReport(
              data.prepareReportData.map((reportData: any) => ({
                ...reportData,
                status: MappingStatusLabel(reportData.status),
                amount: isNumber(reportData.amount)
                  ? toCurrency(reportData.amount, { maximumFractionDigits: 2, minimumFractionDigits: 2 })
                  : "",
              })),
            )
            break
        }
      },
      [exportAllPayReport, exportBLCPReport, exportEclaimReport, exportTaxReport, reportType],
    )

    const handleClickExportReport = useCallback(
      async (filter: any) => {
        const { startDate, endDate, format } = filter
        setReportType(format)
        await fetchExportData({
          variables: {
            reportType: format,
            startDate: formatDate(startDate, "yyyy-MM-dd"),
            endDate: formatDate(endDate, "yyyy-MM-dd"),
          },
        })
      },
      [fetchExportData],
    )

    const startDate = useMemo(() => values.startDate, [values])
    const endDate = useMemo(() => values.endDate, [values])
    return {
      columns,
      tableData,
      values,
      setFieldValue,
      claimStatusOptions,
      clearFilter,
      claimTitleOptions,
      handleFilter,
      employeeOptions,
      goToHistoryDetail,
      pageSize,
      onPageSizeChange,
      userIsHr,

      isExportReportModalOpen,

      handleClickExportReport,
      handleOpenExportReportModal,
      handleCloseExportReportModal,

      endDate,
      startDate,
    }
  }),
)

const DashboardComponent = enhancer(DashboardPage)

export default DashboardComponent
