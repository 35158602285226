import { compose, withHooks } from "enhancers"
import { FormControlLabel, Typography } from "@material-ui/core"
import { Checkbox as FormikMuiCheckbox } from "formik-material-ui"

const CustomCheckbox = (props: any) => (
  <FormControlLabel
    style={{ display: "flex", alignItems: "flex-start", margin: 0 }}
    className={props.className}
    control={
      <FormikMuiCheckbox
        color="primary"
        indeterminate={false}
        style={{ marginLeft: "4px", marginRight: "8px", width: "20px", height: "20px", padding: "2px" }}
        {...props}
      />
    }
    label={
      <Typography variant="body1" style={{ fontSize: 16 }}>
        {props.title}
      </Typography>
    }
  />
)

const enhancer = compose(
  withHooks((props: any, hooks: any) => {
    const { field } = props || {}
    const { value: fieldValue } = field || {}
    const { useState, useEffect } = hooks || {}

    const [checked, setChecked] = useState(false)
    const [value, setValue] = useState(false)

    useEffect(() => {
      setValue(fieldValue)
      setChecked(fieldValue)
    }, [fieldValue])

    return { ...props, value, checked }
  }),
)

export default enhancer(CustomCheckbox)
