import { DialogActions, DialogContent, Dialog as MuiDialog, CircularProgress } from "@material-ui/core"
import { Box, Button, Icon, Typography } from "components"
import { compose, withFormik, withHooks } from "enhancers"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { AppColor } from "theme/app-color"
import { paths } from "utils/helper"

const Dialog = styled(MuiDialog)`
  .MuiDialog-paperScrollPaper {
    padding: 0;
    margin: 1vw;
    min-width: 343px;
    overflow: auto;
  }
`

const ModalContainer = styled(DialogContent)`
  height: 100%;
  padding: 16px;
`

const ContentContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const TitleContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0px auto;
  margin: 16px 0px 16px 0px;
`

const ActionContainer = styled(Box)`
  display: flex;
  width: 100%;
`

const ActionButton = styled(Button)`
  width: 100%;
  border-radius: 12px;
  margin-top: 10px;
`

interface AuthModalProps {
  isOpen: boolean
  isError: boolean
  isSuccess: boolean
  title: string
  description: string
  handleClose: () => void
  goSignInPage: any
}

const AuthModalComponent = (props: AuthModalProps) => {
  return (
    <Dialog open={props.isOpen}>
      <ModalContainer>
        <ContentContainer>
          {props.isError ? (
            <Icon width="40px" height="40px" name="Warning" color={AppColor["Other/Warning"]} />
          ) : (
            checkSuccess(props.isSuccess)
          )}

          <TitleContainer>
            <Typography variant="h3" color="Text/Primary Text">
              {props.title}
            </Typography>
          </TitleContainer>
          <Box mb="4px">
            <Typography variant="body1" color="Text/Primary Text">
              {props.description}
            </Typography>
          </Box>
        </ContentContainer>
        {(props.isError || props.isSuccess) && (
          <DialogActions>
            <ActionContainer>
              <ActionButton variant="contained" onClick={props.goSignInPage}>
                ตกลง
              </ActionButton>
            </ActionContainer>
          </DialogActions>
        )}
      </ModalContainer>
    </Dialog>
  )
}

const enhancer = compose(
  withFormik({}),
  withHooks((props: any, hooks: any) => {
    const { useCallback } = hooks
    const { isOpen, onClose, title, description, isError, isSuccess } = props
    const history = useHistory()

    const goSignInPage = useCallback(() => {
      paths.signInPath().push()
    }, [history])

    return {
      isOpen,
      onClose,
      title,
      description,
      isError,
      isSuccess,
      goSignInPage,
    }
  }),
)

const checkSuccess = (isSuccess: any) => {
  return isSuccess ? (
    <Icon width="40px" height="40px" name="CheckCircleOutline" color={AppColor.Success} />
  ) : (
    <CircularProgress color="primary" />
  )
}

const AuthModal = enhancer(AuthModalComponent)

export default AuthModal
