// import imageCompression from "browser-image-compression"
import imageCompression from "vendor/next-browser-image-compression/browser-image-compression.js"
import { Box } from "components"
import { Button, Icon, IconButton, ImageLightbox, Modal, Notification, Typography } from "components/common"

import { IconName } from "components/common/Icon"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useDropzone } from "react-dropzone"

import styled from "styled-components"
import { AppColor } from "theme/app-color"
import { convertBase64ToFile, convertFileToBase64 } from "utils/helper"
import { previewPdf } from "./PdfViewer"
import { v4 } from "uuid"
import { pdfToImages } from "./PdfConverter"
import Loader from "components/Loader"
import { env } from "configs"
import { isEmpty } from "utils/lodash"

import "./UploadDocumentCard.css"
import { MuiIconCollections } from "constants/interface/material_icon_types"

const ItemContainer = styled.div`
  border: 1px solid ${AppColor["Gray/Flat Button Bg"]};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: calc(100% - 32px);
  margin-top: 16px;
`

const ItemTitleContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  width: 100%;
`

const ContainerFlex = styled.div<{ js: string; row?: boolean; al: string }>`
  display: flex;
  justify-content: ${(props) => props.js};
  align-items: ${(props) => props.al};
  flex-direction: ${(props) => (props.row ? "row" : "column")};
`

const IconContainer = styled.div`
  margin-top: 16px;
  background-color: ${AppColor["Gray/Bg"]};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 8px 0px;
  border-radius: 8px;
`

const ItemImageContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 16px;
  width: 100%;
`

const CustomIconList = styled(Icon)`
  font-size: 16px;
  margin-left: 8px;
`

const ImageTag = styled("img")`
  &:hover {
    cursor: pointer;
  }
`

const ImageContainer = styled.div`
  object-fit: contain;
  width: 200px;
  height: 200px;
`

const ImageRowContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`
const Divider = styled.div`
  height: 16px;
  width: 100%;
  border-bottom: 1px solid ${AppColor["Text/Background"]};
  margin-bottom: 16px;
  display: "&nbsp";
`
// @ts-ignore
const NextButtonContainer = styled.div`
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 50001;
`

const NextButton = ({ onClick = () => {} }: { onClick?: () => void }) => (
  <NextButtonContainer onClick={onClick}>
    <Icon width={48} height={48} name="KeyboardArrowRight" color="#ffffff" />
  </NextButtonContainer>
)

const PreviousButtonContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 50001;
`

const PreviousButton = ({ onClick = () => {} }: { onClick?: () => void }) => (
  <PreviousButtonContainer onClick={onClick}>
    <Icon width={48} height={48} name="KeyboardArrowLeft" color="#ffffff" />
  </PreviousButtonContainer>
)

export interface ImageInfo {
  url: string
  title: string
}

interface FileDataBase64 {
  id: string
  file: string
  name: string
}

interface FileInfo {
  encoding: string
  filename: string
  mimetype: string
}

interface FileDataFile {
  id: string
  file: any
  fileImages?: ImageInfo[]
  width?: number
  height?: number
  url?: string
}

export interface DocumentConfig {
  seq: number
  name: string
  title: string
  iconName: MuiIconCollections
  remark: string
  description: string
  required?: boolean
  amountLimit?: number
  file?: any[]
  isPreview?: boolean
  maxSizeInMB?: number
}

export interface DocumentItemProps extends DocumentConfig {
  canDelete: boolean
  onChange: (files: FileDataFile[], name: string) => void
  onUploading: (seq: number) => void
  onUploaded: (seq: number) => void
  hideLoader?: boolean
  isUploading: boolean
  onLoading?: (values: boolean) => void
}

async function getImageSize(file: File, url?: string): Promise<{ width: number; height: number }> {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => {
      resolve({ width: img.width, height: img.height })
    }
    img.onerror = (error) => {
      reject(error)
    }
    img.src = URL.createObjectURL(file)
    // prevent fetch from url if (url !== undefined) {
    //   img.src = url
    // } else {
    //   img.src = URL.createObjectURL(file)
    // }
  })
}

async function convertImageUrlToFile(imageUrl: string, fileName: string): Promise<File> {
  const response = await fetch(imageUrl)
  const blob = await response.blob()
  return new File([blob], fileName, { type: blob.type })
}

async function compressImageFile(compressedFile: any, maxSizeMB: number): Promise<File> {
  const size1MB = 1048576
  const compressedLangeFile = await imageCompression(compressedFile, {
    maxSizeMB,
    useWebWorker: true,
    preserveExif: true,
    libURL: "https://cdn.jsdelivr.net/npm/browser-image-compression@2.0.2/dist/browser-image-compression.js",
  })
  if (compressedLangeFile.size > size1MB) {
    return await compressImageFile(compressedLangeFile, maxSizeMB)
  }
  return compressedLangeFile
}

async function processFile(fileProps: any[], maxSizeInMB: number, url?: any): Promise<FileDataFile> {
  const file: any = fileProps[0]
  let fileObj: FileDataFile = { id: v4(), file }
  const fileType = file.type ? file.type : file.mimetype

  if (fileType === "application/pdf") {
    fileObj.fileImages = await pdfToImages(file, url)
    const { width, height } = await getImageSize(
      await convertImageUrlToFile(fileObj.fileImages[0].url, "pdf-image.jpg"),
    )
    fileObj.width = width
    fileObj.height = height
  } else {
    const maxSizeMB = maxSizeInMB
    const compressedFile = await compressImageFile(file, maxSizeMB)
    fileObj.file = compressedFile
    const { width, height } = await getImageSize(compressedFile, url)
    fileObj.width = width
    fileObj.height = height
    if (fileType === "image/jpeg") {
      fileObj.file.name = file.name ? file.name : file.filename
    }
  }

  return fileObj
}

const DocumentItem = (props: DocumentItemProps) => {
  const {
    name,
    remark,
    seq,
    title,
    file: initialFiles = [],
    onChange,
    onUploading,
    onUploaded,
    isPreview,
    required,
    maxSizeInMB = 1,
    hideLoader,
    isUploading,
    onLoading,
  } = props

  const isShowLoader = useMemo(() => {
    return hideLoader === undefined || hideLoader === null ? true : !hideLoader
  }, [hideLoader])

  const initState = useRef<FileDataFile[]>([])
  const [isInitStateUpdated, setIsInitStateUpdated] = useState(false)

  const initForPdfUrl = useCallback(
    async (id: any, initData: any, url: any, currentFileName: any) => {
      const processCurFile = await convertImageUrlToFile(url, currentFileName)
      const fileObj = await processFile([processCurFile], maxSizeInMB, url)
      initData.push({
        id,
        file: processCurFile,
        fileImages: fileObj.fileImages,
      })
      // update file if (onChange) {
      //   onChange([...initData], name)
      // }
    },
    [initialFiles, isUploading, onLoading],
  )

  const initForImageUrl = useCallback(
    async (id: any, initData: any, url: any, currentFileName: any) => {
      const processCurFile = await convertImageUrlToFile(url, currentFileName)
      const fileObj = await processFile([processCurFile], maxSizeInMB, url)
      initData.push({
        id,
        file: fileObj.file,
        width: fileObj.width,
        height: fileObj.height,
      })
      // update file if (onChange) {
      //   onChange([...initData], name)
      // }
    },
    [initialFiles, isUploading, onLoading],
  )

  const initForPdf = useCallback(
    async (id: any, initData: any, currentFileName: any, currentFile: any) => {
      if (typeof currentFile === "string") {
        const fileObj = await processFile([convertBase64ToFile(currentFile, currentFileName)], maxSizeInMB)
        initData.push({
          id,
          file: fileObj.file,
          fileImages: fileObj.fileImages,
        })
        // update file if (onChange) {
        //   onChange([...initData], name)
        // }
      } else {
        const fileObj = await processFile([currentFile], maxSizeInMB)
        initData.push({
          id,
          file: currentFile,
          fileImages: fileObj.fileImages,
        })
      }
    },
    [initialFiles, isUploading, onLoading],
  )

  const initForImage = useCallback(
    async (id: any, initData: any, currentFileName: any, currentFile: any) => {
      if (typeof currentFile === "string") {
        const formBase64 = convertBase64ToFile(currentFile, currentFileName)
        const fileObj = await processFile([formBase64], maxSizeInMB)
        initData.push({
          id,
          file: fileObj.file,
          width: fileObj.width,
          height: fileObj.height,
        })
        // update file if (onChange) {
        //   onChange([...initData], name)
        // }
      } else {
        const { width, height } = await getImageSize(currentFile)
        initData.push({
          id,
          file: currentFile,
          width,
          height,
        })
      }
    },
    [initialFiles, isUploading, onLoading],
  )

  const getFileData = useCallback(
    (file: any) => {
      const currentFile = file.file ? file.file : file
      const currentFileType = currentFile.mimetype ? currentFile.mimetype : currentFile.type
      const currentFileName = currentFile.filename ? currentFile.filename : currentFile.name
      return { currentFile, currentFileType, currentFileName }
    },
    [initialFiles, isUploading, onLoading],
  )

  const getType = useCallback(
    (file: any) => {
      return file.mimetype ? file.mimetype : file.type
    },
    [initialFiles, isUploading, onLoading],
  )

  const prepareInitData = useCallback(async () => {
    // onLoading && onLoading(true) open Modal
    const initData: FileDataFile[] = []

    for (const { id, file, url } of initialFiles) {
      const { currentFile, currentFileType, currentFileName } = getFileData(file)
      if (currentFileType === "application/pdf") {
        if (url) {
          await initForPdfUrl(id, initData, url, currentFileName)
        } else {
          await initForPdf(id, initData, currentFileName, currentFile)
        }
        continue // Skip the rest of the loop
      }
      if (url) {
        await initForImageUrl(id, initData, url, currentFileName)
      } else {
        await initForImage(id, initData, currentFileName, currentFile)
      }
    }
    initState.current = initData
    setFiles(initState.current)
    setIsInitStateUpdated(true)
    // onLoading && onLoading(false) close Modal
  }, [initialFiles, isUploading, onLoading])

  const setData = useCallback(async () => {
    if (initialFiles.length === 0) {
      setIsInitStateUpdated(false)
      isShowLoading && onLoading && onLoading(false)
    } else {
      // isShowLoading prevent loading modal when uploade/delete file
      isShowLoading && onLoading && onLoading(true)
      await prepareInitData()
      isShowLoading && onLoading && onLoading(false)
    }
  }, [initialFiles, isUploading, onLoading, isInitStateUpdated])

  useEffect(() => {
    setData()
  }, [initialFiles])

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isShowLoading, setIsShowLoading] = useState<boolean>(true)
  const [files, setFiles] = useState<FileDataFile[]>([])
  const [isLightboxOpen, setIsLightboxOpen] = useState(false)
  const [currentOpenFileIndex, setCurrentOpenFileIndex] = useState(-1)

  const filesCount = useMemo(() => files.length, [files])

  useEffect(() => {
    if (isInitStateUpdated) {
      setFiles(initState.current)
      if (onChange) {
        onChange(files, name)
      }
    }
  }, [isInitStateUpdated])

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: [".png", ".jpg", ".pdf", ".jpeg"],
    multiple: false,
    // maxSize: 1048576,
    onDropAccepted: (fileProps: File[]) => {
      const handleDrop = async () => {
        const loadingTimeout = setTimeout(() => setIsLoading(true), 500) // Start the timer
        onUploading(seq)
        // const maxSizeInbytes = maxSizeInMB * 1048576
        try {
          setIsShowLoading(false)
          const fileObj = await processFile(fileProps, maxSizeInMB)
          onUploaded(seq)
          onChange([...files, fileObj], name)
          setFiles([...files, fileObj])
        } catch (error) {
          console.error(error)
          onUploaded(seq)
          // @ts-ignore'
          Notification.error("Upload unsuccess please check your file")
        } finally {
          clearTimeout(loadingTimeout)
          setIsLoading(false)
        }
      }

      handleDrop()
    },
  })

  const previewFileIndex = useCallback(
    (fileIndex: number) => {
      const targetFile = files[fileIndex]
      const fileType = targetFile.file.mimetype ? targetFile.file.mimetype : targetFile.file.type
      setIsLightboxOpen(true)
      setCurrentOpenFileIndex(fileIndex)
      // @ts-ignore
      ImageLightbox.close()
      switch (fileType) {
        case "application/pdf":
          document.body.classList.remove("current-show-single-image-lightbox")

          setTimeout(() => {
            document.body.classList.remove("on-switch-image-lightbox")
            // @ts-ignore
            ImageLightbox.open({
              images: targetFile.fileImages,
              onClose: () => {
                // document.body.classList.remove("current-show-single-image-lightbox")
                setCurrentOpenFileIndex(-1)
                setIsLightboxOpen(false)
              },
            })
          }, 100)
          break
        case "image/jpeg":
        case "image/png":
          document.body.classList.add("current-show-single-image-lightbox")
          setTimeout(() => {
            document.body.classList.remove("on-switch-image-lightbox")
            // @ts-ignore
            ImageLightbox.open({
              image: targetFile.url ? targetFile.url : URL.createObjectURL(targetFile.file),
              // images: [URL.createObjectURL(targetFile.file)],
              onClose: () => {
                document.body.classList.remove("current-show-single-image-lightbox")
                setCurrentOpenFileIndex(-1)
                setIsLightboxOpen(false)
              },
            })
          }, 100)
          break
        default:
          break
      }
    },
    [files],
  )

  const isCurrentFirstAttachmentOpen = useMemo(() => {
    return currentOpenFileIndex === 0
  }, [currentOpenFileIndex])

  const isCurrentLastAttachmentOpen = useMemo(() => {
    return currentOpenFileIndex === filesCount - 1
  }, [currentOpenFileIndex, filesCount])

  const gotoNextAttachment = useCallback(() => {
    if (!isCurrentLastAttachmentOpen) {
      document.body.classList.add("on-switch-image-lightbox")
      previewFileIndex(currentOpenFileIndex + 1)
    }
  }, [currentOpenFileIndex, isCurrentLastAttachmentOpen, previewFileIndex])

  const gotoPreviousAttachment = useCallback(() => {
    if (!isCurrentFirstAttachmentOpen) {
      document.body.classList.add("on-switch-image-lightbox")
      previewFileIndex(currentOpenFileIndex - 1)
    }
  }, [currentOpenFileIndex, isCurrentFirstAttachmentOpen, previewFileIndex])

  const isShowPreviousButton = useMemo(() => filesCount > 1 && isLightboxOpen && !isCurrentFirstAttachmentOpen, [
    filesCount,
    isLightboxOpen,
    isCurrentFirstAttachmentOpen,
  ])

  const isShowNextButton = useMemo(() => filesCount > 1 && isLightboxOpen && !isCurrentLastAttachmentOpen, [
    filesCount,
    isLightboxOpen,
    isCurrentLastAttachmentOpen,
  ])

  const handleRemoveFile = useCallback(
    (confirmProps: any, id: string) => {
      confirmProps.close()
      setIsShowLoading(false)
      const filtered = files.filter((file) => file.id !== id)
      setFiles(filtered)
      initState.current = filtered
      onChange(filtered, name)
    },
    [files, onChange, name],
  )

  const onClickRemoveFile = useCallback(
    (id: string) => {
      // @ts-ignore
      Modal.confirm({
        className: "deleteConFirmModal",
        title: "",
        children: (
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Box mb="16px">
              <Icon
                color={AppColor["Error/Error Text"]}
                name={env.ICON_CONFIG.DELETE_FILE as IconName}
                height="40px"
                width="40px"
              />
            </Box>
            <Box mb="8px">
              <Typography variant="h3" color={AppColor["Text/Primary Text"]}>
                ลบไฟล์
              </Typography>
            </Box>
            <Box mb="16px">
              <Typography variant="body1" color={AppColor["Text/Primary Text"]}>
                คุณยืนยันที่จะลบหรือไม่
              </Typography>
            </Box>
          </Box>
        ),
        okButtonLabel: (
          <Box style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
            <Icon name="Check_circle" color={`${AppColor["Primary/Primary Text"]}`} />
            <Typography variant="body1">ยืนยันลบ</Typography>
          </Box>
        ),
        okButtonVariant: "outlined",
        cancelButtonLabel: (
          <Box style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
            <Icon name="cancel" />
            <Typography variant="body1">ยกเลิก</Typography>
          </Box>
        ),
        cancelButtonVariant: "contained",
        buttonWidth: 147,
        swapButton: true,
        onOk: (props: any) => handleRemoveFile(props, id),
      })
    },
    [handleRemoveFile],
  )

  const fileComponent = useMemo(() => {
    return files.map(({ id, file, fileImages, url }, fileIndex: number) => (
      <>
        <ImageRowContainer
          style={{ position: "relative", overflow: "hidden", backgroundColor: AppColor["Text/Background"] }}
          key={id}
        >
          <ImageContainer style={{ width: "fit-content" }}>
            {getType(file) === "application/pdf" ? (
              <ImageTag
                height={200}
                src={fileImages?.[0].url}
                alt={file.filename ? file.filename : file.name}
                onClick={() => previewFileIndex(fileIndex)}
              />
            ) : (
              <ImageTag
                height={200}
                src={url ? url : URL.createObjectURL(file)}
                alt={file.filename ? file.filename : file.name}
                onClick={() => previewFileIndex(fileIndex)}
              />
            )}
          </ImageContainer>
          {!isPreview && !isUploading && (
            <IconButton onClick={() => onClickRemoveFile(id)} style={{ top: 0, right: 0, position: "absolute" }}>
              <Icon
                color={AppColor["Error/Error Text"]}
                name={env.ICON_CONFIG.DELETE as IconName}
                width={24}
                height={24}
              />
            </IconButton>
          )}
        </ImageRowContainer>
        {files.length > 1 && <Divider />}
      </>
    ))
  }, [files, isPreview, onClickRemoveFile, previewFileIndex, isUploading])

  return (
    <ItemContainer>
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
      </div>
      <ItemTitleContainer>
        <ContainerFlex js="" al="start" row>
          <Box mr={2}>
            <Typography variant="h4">{`${seq}. `}</Typography>
          </Box>
          <ContainerFlex js="" al="start">
            <Box display="flex">
              <Typography variant="h4" color="Text/Black2">
                <span>{title}</span>
                <span style={{ color: AppColor["Other/Danger"] }}>{required ? " *" : ""}</span>
              </Typography>
            </Box>
            <Box mt={1}>
              <Typography variant="subtitle1" color={AppColor["Text/Secondary Text"]}>
                {remark ? `(${remark})` : ""}
              </Typography>
            </Box>
          </ContainerFlex>
        </ContainerFlex>
        <Box ml={1}>
          <ContainerFlex js="" al="end" row>
            {!props.isPreview && (
              <Button
                type="button"
                onClick={open}
                style={{ minWidth: "106px" }}
                variant="outlined"
                disabled={isUploading}
              >
                <Box style={{ display: "flex", alignItems: "center", textAlign: "center" }}>
                  <Typography variant="body1">เพิ่มไฟล์</Typography>
                  <CustomIconList name={env.ICON_CONFIG.ADD_FILE_BUTTON as IconName} />
                </Box>
              </Button>
            )}
          </ContainerFlex>
        </Box>
      </ItemTitleContainer>
      {files.length === 0 && !isLoading && (
        <IconContainer>
          {<Icon name="Description" color={AppColor["Text/Gray Success"]} style={{ fontSize: "60px" }} />}
        </IconContainer>
      )}

      {files.length > 0 && (
        <Box display="flex" justifyContent="center">
          <ItemImageContainer>{fileComponent}</ItemImageContainer>
        </Box>
      )}
      {isShowLoader && isLoading && (
        <Box display="flex" justifyContent="center">
          <Loader />
        </Box>
      )}

      {isShowNextButton && <NextButton onClick={gotoNextAttachment} />}
      {isShowPreviousButton && <PreviousButton onClick={gotoPreviousAttachment} />}
    </ItemContainer>
  )
}

const Container = styled.div<{ mb?: string }>`
  margin-top: 40px;
  margin-bottom: ${(props) => (props.mb ? props.mb : "40px")};
`

const Items = styled.div`
  background-color: ${AppColor["White / White"]};
`

export interface UploadDocumentCardProps {
  documents: DocumentConfig[]
  values: any
  onChange: (values: any) => void
  onUploading?: () => void
  onUploaded?: () => void
  canDelete?: boolean
  isPreview?: boolean
  maxSizeInMB?: number
  mb?: string
  hideLoader?: boolean
  isUploading?: boolean
  onLoading?: (values: boolean) => void
}

export const UploadDocumentCard = (props: UploadDocumentCardProps) => {
  const {
    documents,
    values: documentValues,
    onChange,
    canDelete = true,
    isPreview = false,
    maxSizeInMB = 1,
    mb,
    onUploading = () => {},
    onUploaded = () => {},
    hideLoader = false,
    isUploading = false,
    onLoading,
  } = props

  const [uploadingCount, setUploadingCount] = useState<number>(0)
  const [uploadedCount, setUploadedCount] = useState<number>(0)

  const handleChangeFile = useCallback(
    async (values: FileDataFile[], name: string) => {
      const responseDocumentValues = { ...documentValues }
      const findKeyName = name in responseDocumentValues

      const saveFiles = []
      for (const { id, file, url } of values) {
        const currentFile = file.file ? file.file : file
        saveFiles.push({
          id,
          file: currentFile,
          url,
        })
      }

      if (!isEmpty(responseDocumentValues) && findKeyName) {
        responseDocumentValues[name] = saveFiles
        if (onChange) {
          onChange(responseDocumentValues)
        }
      }
    },
    [documentValues, onChange],
  )

  const isShowItem = (hasFile?: boolean) => {
    if (isPreview) return hasFile

    return true
  }

  const handleUploading = useCallback(
    (seq: number) => {
      setUploadingCount((u) => u + 1)
    },
    [setUploadingCount],
  )

  const handleUploaded = useCallback(
    (seq: number) => {
      setUploadedCount((u) => u + 1)
    },
    [setUploadedCount],
  )

  useEffect(() => {
    // Trigger UploadDocumentCard on uploading or uploaded
    if (uploadingCount === uploadedCount) {
      onUploaded()
    } else {
      onUploading()
    }
  }, [uploadingCount, uploadedCount, onUploading, onUploaded])

  return (
    <Container mb={mb}>
      {documents.length > 0 && (
        <ContainerFlex js="" al="center" row style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
          <Icon width={24} height={24} name={env.ICON_CONFIG.ATTACHMENT as IconName} color={AppColor["Primary/Line"]} />
          <Box style={{ display: "flex", gap: "8px", flexDirection: "row", alignItems: "center" }}>
            <Typography variant="h3" color="Text/Black2">
              {isPreview ? "เอกสาร" : "แนบเอกสาร"}
            </Typography>
            <Typography variant="subtitle1" color={AppColor["Text/Secondary"]}>
              {isPreview ? "" : "(jpg, png, pdf)"}
            </Typography>
          </Box>
        </ContainerFlex>
      )}
      <Items>
        {documents.map((doc, _index) => {
          if (isShowItem(documentValues[doc.name] && !isEmpty(documentValues[doc.name]))) {
            return (
              <DocumentItem
                key={`${doc.seq}-${doc.title}`}
                seq={doc.seq}
                name={doc.name}
                title={doc.title}
                iconName={doc.iconName}
                remark={doc.remark}
                canDelete={canDelete}
                onChange={handleChangeFile}
                onUploading={handleUploading}
                onUploaded={handleUploaded}
                file={documentValues[doc.name]}
                required={doc.required}
                isPreview={isPreview}
                maxSizeInMB={maxSizeInMB}
                hideLoader={hideLoader}
                description={doc.description}
                isUploading={isUploading}
                onLoading={onLoading}
              />
            )
          } else {
            return null
          }
        })}
      </Items>
    </Container>
  )
}
