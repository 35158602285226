import { Card, CardContent } from "@material-ui/core"
import { Box, Button, Divider, Grid, Typography, Icon } from "components"
import dayjs from "dayjs"
import { compose, withFormik, withHooks } from "enhancers"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { AppColor } from "theme/app-color"
import { paths } from "utils/helper"
import { IconName } from "components/common/Icon"
import { env } from "configs"
import PeriodSection from "components/PeriodSection"
import { ClaimCard } from "components/advance/ClaimCard"

const WarningBox = styled(Box)<{ mt?: string; mb?: string }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 8px;
  height: 32px;
  background-color: ${AppColor["System/Warning Light"]};
  border-radius: 4px;
  margin-top: ${(props) => (props.mt ? props.mt : "0px")};
  margin-bottom: ${(props) => (props.mb ? props.mb : "0px")};
`

const Container = styled.div<{
  row?: boolean
  height?: string
  width?: string
}>`
  background-color: ${AppColor["White / White"]};
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 24px;
  margin-bottom: 8px;
  border-radius: 8px;
`

const ContainerFlex = styled.div<{ js: string; row?: boolean; w100?: boolean; al: string }>`
  display: flex;
  justify-content: ${(props) => props.js};
  align-items: ${(props) => props.al};
  flex-direction: ${(props) => (props.row ? "row" : "column")};
  width: ${(props) => (props.w100 ? "100%" : "none")};
`

const SummaryItems = styled.div`
  margin-top: 16px;
  padding: 16px;
  background: ${AppColor["White / White"]};
  border: 0.5px solid ${AppColor["Gray/Flat Button Bg"]};
  box-shadow: 0px 4px 4px rgba(218, 218, 218, 0.25);
  border-radius: 8px;
`

const SummaryItem1 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  margin: 0px 0px 8px 0px;
`

const SummaryItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  margin: 8px 0px 0px 0px;
`

const Categories = styled.div<{ color: string }>`
  background-color: ${(props) => props.color};
  border-radius: 4px;
  height: fit-content;
  min-width: fit-content;
  padding: 2px 4px;
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
  margin-right: 10px;
`

const IconBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
`

const PeriodBox = styled(Box)`
  margin-top: 16px;
  padding-top: 16px;
  border-top: 0.5px solid ${AppColor["Primary/Primary Text"]};
`

const SuccessPageComponent = (props: any) => (
  <div style={{ padding: 16 }}>
    {props.currentUserIsHr && props.hrInstead && (
      <WarningBox mt="8px" mb="24px">
        <Icon
          name="Warning"
          color={AppColor["Warning"]}
          width={"16px"}
          height={"16px"}
          style={{ marginRight: "4px" }}
        ></Icon>
        <Typography variant="body2" color={AppColor["Text/Primary Text"]}>
          กำลังทำรายการแทนพนักงานคนอื่น
        </Typography>
      </WarningBox>
    )}
    <Grid container>
      <Grid container>
        <ContainerFlex js="center" w100 al="center">
          <Typography variant="h3" color="White / White">
            ส่งเรื่องเบิกเรียบร้อยแล้ว
          </Typography>
          <Icon
            name="CheckCircleOutline"
            style={{ margin: "12px" }}
            color={AppColor["Approve / Waiting "]}
            width={"56px"}
            height={"56px"}
          />
          <Typography variant="subtitle1" color="White / White">
            เลขทำรายการ : {props.referenceId}
          </Typography>
          <Typography variant="subtitle1" color="White / White">
            {dayjs(props.createdAt).format("DD/MM/YYYY")}
          </Typography>
        </ContainerFlex>
        <Container height="76px" width="inherit">
          <ContainerFlex js="center" al="center">
            <Typography variant="body1" color="Primary/Primary Text">
              เจ้าหน้าที่กำลังตรวจสอบคำขอเบิก
            </Typography>
            <Typography variant="body1" color="Primary/Primary Text">
              เมื่อมีความคืบหน้าระบบจะแจ้งเตือนไปที่อีเมล
            </Typography>
            <Typography variant="body1" color="Primary/Primary Text">
              ของคุณ
            </Typography>
          </ContainerFlex>
        </Container>

        {props.isShowPeriodSection && (
          <Box width="inherit">
            <PeriodSection
              p="8px"
              textVariant="subtitle1"
              textColor="Text/Secondary"
              disableConfirm={props.disableConfirm}
              name={props.name}
              step={4}
              referenceId={props.referenceId}
              createdDate={props.createdDate}
              amount={props.amount}
            />
          </Box>
        )}
        <Box width="inherit">
          <Typography variant="subtitle1" color="White / White">
            *สามารถเช็คสถานะได้ที่ “ประวัติการเบิก” หรือรอรับแจ้งเตือนบอกผลการยื่นคำขอ
          </Typography>
        </Box>
      </Grid>
    </Grid>
    <div style={{ marginTop: "60px", marginBottom: "40px" }}>
      <Link to="" style={{ textDecoration: "none" }} onClick={props.handleClickBack}>
        <Button
          variant="contained"
          color={AppColor["White / White"]}
          width={`100%`}
          startIcon={env.ICON_CONFIG.BACK_HOME ? <Icon name={env.ICON_CONFIG.BACK_HOME as IconName} /> : <></>}
        >
          กลับหน้าแรก
        </Button>
      </Link>
    </div>
    <ContainerFlex js="start" row w100 al="center">
      <Icon
        name={env.ICON_CONFIG.SUCCESS_DETAIL_LABEL as IconName}
        style={{ marginRight: "8px" }}
        color={AppColor["Primary/Primary Text"]}
        width={24}
        height={24}
      />
      <Typography variant="h3" color="Text/Black2">
        รายละเอียดการยื่นคำขอ
      </Typography>
    </ContainerFlex>
    <Box style={{ marginTop: "16px" }}>
      <ClaimCard
        title={props.requestName}
        type={props.type}
        name={props.name}
        approvedRequest={props.approvedCount}
        totalAmount={props.approvedAmount}
        icon={props.icon}
        iconColor={props.iconColor}
        chipTitleColor={props.chipTitleColor}
        chipBackgroundColor={props.chipBackgroundColor}
        fullSize
        displayOnly
      />
    </Box>
    {env.COIN_SOURCE === "coin_module" && (
      <div style={{ marginBottom: "24px", marginTop: "32px" }}>
        <div style={{ display: "flex", alignItems: "center", gap: "8px", marginBottom: "16px" }}>
          <Icon name="point" />
          <Typography variant="h3" color="black">
            สรุปการใช้คะแนน
          </Typography>
        </div>
        <Card style={{ border: "1px solid #d4d4d4", borderRadius: "8px" }}>
          <CardContent style={{ padding: "16px" }}>
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "8px" }}>
              <Typography variant="h4" color={AppColor["Text/Primary Text"]}>
                คะแนนที่ใช้
              </Typography>
              <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <Typography variant="Body/16" color={AppColor["Primary/Primary Text"]} isCurrency>
                  {props.amount}
                </Typography>
                <Typography variant="Body/16" color={AppColor["Primary/Primary Text"]}>
                  คะแนน
                </Typography>
              </div>
            </div>
            <div style={{ marginTop: "8px" }} />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Typography variant="h4" color={AppColor["Text/Line"]} isCurrency>
                คงเหลือ {props.currentPoint - props.amount} คะแนน
              </Typography>
            </div>
          </CardContent>
        </Card>
      </div>
    )}
  </div>
)

const enhancer = compose(
  withFormik({}),
  withHooks((props: any, hooks: any) => {
    const {
      isRepresent,
      amount,
      requestName,
      type,
      requester,
      icon,
      iconColor,
      chipTitleColor,
      chipBackgroundColor,
      disableConfirm,
      disableConfirmRefresh,
      isShowPeriodSection,
      approvedRequest,
      totalAmount,
    } = props
    const { useCallback, useEffect } = hooks

    const handleClickBack = useCallback(() => {
      disableConfirm()
      paths.landingPath().push()
    }, [disableConfirm])

    useEffect(() => {
      disableConfirmRefresh()
    }, [disableConfirmRefresh])

    return {
      isRepresent,
      amount,
      requestName,
      type,
      requester,
      icon,
      iconColor,
      chipTitleColor,
      chipBackgroundColor,
      handleClickBack,
      isShowPeriodSection,
      approvedRequest,
      totalAmount,
      ...props,
    }
  }),
)

const SuccessPage = enhancer(SuccessPageComponent)

export default SuccessPage
