import { Box, Icon, Typography } from "components"
import { IconName } from "components/common/Icon"

export interface CustomFieldProps {
  title: string
  iconColor: string
  icon: IconName
}

export const Label = (props: CustomFieldProps) => {
  const { iconColor, icon, title } = props

  return (
    <Box display="flex" alignItems="center">
      <Box mr={2}>
        <Icon width="24px" height="24px" name={icon} color={iconColor} />
      </Box>
      <Typography variant="h3" color="Text/Black2">
        {title}
      </Typography>
    </Box>
  )
}
