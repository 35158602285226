import React from "react"
import MuiTextField from "@material-ui/core/TextField"
import { Autocomplete } from "formik-material-ui-lab"
import { compose, defaultProps, withHooks } from "enhancers"
import { find, map, get, isNil } from "utils/lodash"
import ListboxComponent from "./ListboxComponent"
import Hidden from "components/common/Hidden"
import Fuse from "fuse.js"

import InputAdornment from "@material-ui/core/InputAdornment"

import {
  borders,
  display,
  flexbox,
  palette,
  positions,
  shadows,
  sizing,
  spacing,
  typography,
} from "@material-ui/system"
import styled from "styled-components/macro"

const makeBoxProps = (Component) =>
  styled(Component)(borders, display, flexbox, palette, positions, shadows, sizing, spacing, typography)

const TextField = makeBoxProps(MuiTextField)

export const enhancer = compose(
  defaultProps({
    options: [],
    forcefix: true,
  }),
  withHooks((props, hooks) => {
    const {
      label,
      options: optionList,
      fuse,
      transformDisplay,
      setAdditionalList = () => {},
      additionalList,
      ...restProps
    } = props
    const { useMemo, useCallback, useState, useEffect } = hooks
    const [options, setOptions] = useState(optionList)

    const customOptions = useMemo(() => {
      return map(options, "value")
    }, [options])

    const freeSolo = props.freeSolo
    const customGetOptionLabel = useCallback(
      (value) => {
        const item = find(options, { value })
        const label = item?.label || ""

        return getValue(freeSolo, label, value)
      },
      [options, freeSolo],
    )

    const setFieldValue = props.form.setFieldValue
    const fieldName = props.field.name

    const handleKeydown = useCallback(
      (event) => {
        if (event.key === "Enter" && fieldName === "disease" && event.target.value) {
          const findValue = options.find((option) => option.value === event.target.value)
          if (findValue) return
          const newObject = {
            label: event.target.value,
            value: event.target.value,
          }
          const optionList = [...options, newObject]
          setFieldValue(fieldName, event.target.value)
          setOptions(optionList)
          setAdditionalList(event.target.value)
        }
      },
      [fieldName, options, setAdditionalList, setFieldValue],
    )

    // set submitCount because formik doesn't touch field when submit. That's bad มากๆ see:https://github.com/jaredpalmer/formik/issues/445
    const touched = get(props.form.touched, props.field.name) || props.form.submitCount > 0
    const error = get(props.form.errors, props.field.name)
    const value = props.field.value
    const required = props.required
    const helperText = props.helperText
    const placeholder = props.placeholder
    const icon = find(options, { value })?.icon
    const renderInput = useCallback(
      (params) => (
        <TextField
          {...params}
          error={touched && !!error}
          helperText={(touched && error) || helperText}
          placeholder={placeholder}
          label={label}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          value={value}
          required={required}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                <Hidden when={!icon}>
                  <InputAdornment position="start">{icon}</InputAdornment>
                </Hidden>
                {params.InputProps.startAdornment}
              </>
            ),
          }}
          onKeyDown={handleKeydown}
        />
      ),
      [touched, error, helperText, placeholder, label, value, required, icon, handleKeydown],
    )

    const filterOptions = useCallback(
      (options, { inputValue }) => {
        if (inputValue || options.length > 200) {
          options = new Fuse(props.options, {
            shouldSort: true,
            threshold: 0.6,
            location: 0,
            distance: 100,
            maxPatternLength: 32,
            minMatchCharLength: 1,
            keys: ["value"],
            id: "label",
          }).search(inputValue ?? "", { limit: 5 })
          options = map(options, "item.value")
        }
        return options
      },
      [props.options],
    )

    const autoSelect = props.freeSolo && isNil(props.autoSelect) ? true : props.autoSelect

    useEffect(() => {
      let newList = []
      if (additionalList !== undefined) {
        newList = additionalList.map((additional) => ({
          label: additional,
          value: additional,
        }))
      }
      setOptions([...optionList, ...newList])
    }, [additionalList, optionList])

    return {
      key: props.forcefix ? `${value}-${JSON.stringify(customOptions)}` : undefined,
      ...restProps,
      options: [...customOptions],
      getOptionLabel: customGetOptionLabel,
      renderInput,
      ListboxComponent,
      filterOptions: fuse ? filterOptions : undefined,
      blurOnSelect: true,
      autoSelect,
    }
  }),
)

function getValue(freeSolo, label, value) {
  if (!label && freeSolo) {
    return value
  } else {
    return label
  }
}

export default enhancer(Autocomplete)
