import { compose, withHooks } from "enhancers"
import { Select } from "components"
import { gql } from "utils/helper"
import { relation } from "constants/objects/relation"
import { env } from "configs"
const RelationSelect = (props: any) => <Select {...props} />

const API = {
  GET_DEPENDENTS: gql`
    query GET_DEPENDENTS($relationships: [String!]!, $requesterId: String!, $filterOptions: JSON!) {
      userDependents(
        input: { relationships: $relationships, requesterId: $requesterId, filterOptions: $filterOptions }
      ) {
        id
        name
        relation
        birthDate
      }
    }
  `,
}

const enhancer = compose(
  withHooks((props: any, hooks: any) => {
    const { options = [], requester, form, filterOptions = {} } = props
    const { setFieldValue } = form || {}
    const { useMemo, useQuery, useEffect } = hooks
    const { data: dependents } = useQuery(API.GET_DEPENDENTS, {
      variables: { relationships: options || [], requesterId: requester?.id, filterOptions },
    })

    const customOptions = useMemo(() => {
      const response = dependents?.userDependents || []

      const familyOptions = response.map((dependent: any) => ({
        label: `[${
          env.MAPPING_DEPENDENT_RELATIONS[dependent.relation as keyof typeof env.MAPPING_DEPENDENT_RELATIONS]
        }] ${dependent.name}`,
        value: dependent.relation === env.MAPPING_DEPENDENT_RELATIONS.Self ? dependent.id : dependent,
      }))

      return familyOptions
    }, [dependents?.userDependents])

    useEffect(() => {
      setFieldValue("userDependent", dependents?.userDependents)
    }, [dependents?.userDependents, setFieldValue])

    return {
      ...props,
      options: customOptions,
    }
  }),
)

export default enhancer(RelationSelect)
