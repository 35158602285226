import { Step, StepLabel, Stepper } from "@material-ui/core"
import { Box, Icon, Typography } from "components"
import { EnumApprovalStepStatus } from "constants/enums/approval-step-status"
import { EnumClaimRequestStatus } from "constants/enums/claim-request-status"
import styled from "styled-components"
import { AppColor } from "theme/app-color"
import { env } from "configs"

const CustomStepper = styled(Stepper)<{ claimstatus: EnumClaimRequestStatus }>`
  .MuiStepConnector-root.MuiStepConnector-vertical {
    margin-left: 6px;
    padding: 0px;
  }
  .MuiStepConnector-lineVertical {
    min-height: 26px;
    margin-top: -9px;
    margin-bottom: -9px;
    width: 1px;
  }
`

const CustomStepLabel = styled(StepLabel)`
  .MuiStepIcon-root {
    font-size: 14px;
  }
`

const MappingStatusLabel = (status: EnumClaimRequestStatus) => {
  switch (status) {
    case EnumClaimRequestStatus.DRAFT:
      return "แบบร่าง"
    case EnumClaimRequestStatus.APPROVED:
      return "อนุมัติแล้ว"
    case EnumClaimRequestStatus.WAITING:
      return "รออนุมัติ"
    case EnumClaimRequestStatus.REJECTED:
      return "ไม่อนุมัติ"
    case EnumClaimRequestStatus.CANCELED:
      return "ยกเลิก"
    default:
      break
  }
}

const StatusIcon = styled(Icon)`
  background-color: ${AppColor["Primary/Line"]};
  z-index: 1;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  height: 10px;
  width: 10px;
`

const StatusStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean; error?: boolean }
}>(({ ownerState }) => ({
  backgroundColor: AppColor["Warning"],
  zIndex: 1,
  color: AppColor["White / White"],
  width: 10,
  height: 10,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  border: `1px solid ${AppColor["White / White"]}`,
  alignItems: "center",
  ...(ownerState.active && {
    background: AppColor["Warning"],
  }),
  ...(ownerState.completed && {
    background: AppColor["Success"],
  }),
  ...(ownerState.error && {
    background: AppColor["Error/Error Text"],
  }),
}))

function StatusStepIcon(props: any) {
  const { active, completed, error, className } = props
  if (env.TENANT_ID === "scg") {
    return <StatusStepIconRoot ownerState={{ completed, active, error }} className={className}></StatusStepIconRoot>
  }

  if (error && completed) {
    return <StatusIcon name="stop_circle" color={AppColor["Other/Danger"]} />
  }

  if (error) {
    return <StatusIcon name="Cancel" color={AppColor["Other/Danger"]} />
  }

  if (active) {
    return <StatusIcon name="PauseCircleFilled" color="#FFE100" />
  }

  if (completed) {
    return <StatusIcon name="CheckCircle" color={AppColor["Success"]} />
  }
  return <StatusIcon name="adjust" color={AppColor["Text/Gray Success"]} />
}

interface StepStatusProps {
  claimStatus: EnumClaimRequestStatus
  workflows: []
  workflowStep: number
  canceled?: string
}

export const StepStatus = (props: StepStatusProps) => {
  const { workflowStep, claimStatus, workflows, canceled } = props
  const labelProps: {
    optional?: React.ReactNode
    active?: boolean
    error?: boolean
    completed?: boolean
  } = { active: false, error: true, completed: true }

  return (
    <>
      {claimStatus === EnumClaimRequestStatus.CANCELED ? (
        <CustomStepper orientation="vertical" style={{ padding: 0, background: "none" }} claimstatus={claimStatus}>
          <Step>
            <CustomStepLabel {...labelProps} StepIconComponent={StatusStepIcon}>
              <Box display="flex" alignItems="center">
                <Box minWidth="80px" mr="8px">
                  <Typography color="White / White" variant="body1">
                    ยกเลิก
                  </Typography>
                </Box>
                <Typography color="White / White" variant="subtitle1">
                  ({canceled})
                </Typography>
              </Box>
            </CustomStepLabel>
          </Step>
        </CustomStepper>
      ) : (
        <CustomStepper
          orientation="vertical"
          style={{ padding: 0, background: "none", width: "100%" }}
          claimstatus={claimStatus}
        >
          {workflows.map((flow: any, index: number) => {
            const labelProps: {
              optional?: React.ReactNode
              active?: boolean
              error?: boolean
              completed?: boolean
            } = {}
            if (flow.status === EnumApprovalStepStatus.WAITING) labelProps.active = true
            else if ([EnumApprovalStepStatus.REJECTED].includes(flow.status)) labelProps.error = true
            else if (flow.status === EnumApprovalStepStatus.APPROVED) {
              labelProps.completed = true
              labelProps.active = false
            }
            return (
              <Step key={flow.seq}>
                <CustomStepLabel {...labelProps} StepIconComponent={StatusStepIcon}>
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Box minWidth="80px" mr="8px">
                      <Typography color="White / White" variant="body1">
                        {claimStatus === EnumClaimRequestStatus.REJECTED &&
                        flow.status === EnumClaimRequestStatus.WAITING
                          ? "-"
                          : MappingStatusLabel(flow.status)}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        color="White / White"
                        variant="subtitle1"
                        style={{
                          overflow: "hidden",
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 1,
                          textOverflow: "ellipsis",
                          wordBreak: "break-all",
                          whiteSpace: "normal",
                        }}
                      >
                        {flow.approverTxt}
                      </Typography>
                    </Box>
                  </Box>
                </CustomStepLabel>
              </Step>
            )
          })}
        </CustomStepper>
      )}
    </>
  )
}
